import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AddPluginModalComponent } from 'src/app/components/bank/add-plugin-modal/add-plugin-modal.component';
import { SharedService } from 'src/app/components/development/shared.service';
import { ServerService } from 'src/app/service/server.service';
import { environment } from 'src/environments/environment';

declare var require
const Swal = require('sweetalert2')

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss']
})
export class AuthenticationComponent implements OnInit {
  @ViewChild('basicAuthModal') basicAuthModal: ModalDirective;
  @ViewChild('keyAuthModal') keyAuthModal: ModalDirective;
  @ViewChild('oauth2Modal') oauth2Modal: ModalDirective;
  @ViewChild('jwtModal') jwtModal: ModalDirective;
  @ViewChild('hmacAuthModal') hmacAuthModal:ModalDirective;
  @Output("showModalPlugin") showModalPlugin: EventEmitter<any> = new EventEmitter();
  @Output("reloadDatatables") reloadDatatables: EventEmitter<any> = new EventEmitter();


  @Input() isModal: boolean;
  @Input() envi: string;
  @Input() id: number;
  @Input() fromMenu: string;
  @Input() isCredentials: boolean;
  tittle: string

  pluginId: number

  isLoading: boolean




  basicAuthSaveUrl = environment.path.base + environment.path.api_management.base + environment.path.api_management.basicAuth
  keyAuthSaveUrl = environment.path.base + environment.path.api_management.base + environment.path.api_management.keyAuth
  oauth2SaveUrl = environment.path.base + environment.path.api_management.base + environment.path.api_management.oauth2
  hmacAuthSaveUrl = environment.path.base + environment.path.api_management.base + environment.path.api_management.hmacAuth
  jwtSaveUrl = environment.path.base + environment.path.api_management.base + environment.path.api_management.jwtAuth





  public basicAuthForm: FormGroup;
  public keyAuthForm: FormGroup;
  public oauth2Form: FormGroup;
  public jwtForm: FormGroup;
  public hmacAuthForm: FormGroup;
  key: String
  isEdit:Boolean = false



  constructor(private fb: FormBuilder,
    private server: ServerService,
    private shared: SharedService) {
    
   
    this.basicAuthForm = fb.group({
      anonymous: [null,[Validators.pattern(/^[a-zA-Z0-9!@#\$%\^\&*\)\(+=._ -]+$/) ]],
      credentials: [false],
      enabled: [true],
      username:[null,[Validators.pattern(/^[a-zA-Z0-9!@#\$%\^\&*\)\(+=._ -]+$/) ]],
      password:[null,[Validators.pattern(/^[a-zA-Z0-9!@#\$%\^\&*\)\(+=._ -]+$/) ]],
    });

    this.keyAuthForm = fb.group({
      keyNames: [[],[Validators.pattern(/^[a-zA-Z0-9!@#\$%\^\&*\)\(+=.,_ -]+$/) ]],
      credentials: [false],
      anonymous: [null],
      keyInHeader: [true],
      keyInQuery: [true],
      keyInBody: [false],
      runOnPreflight: [true],
      enabled: [true],
      key:[null,[Validators.pattern(/^[a-zA-Z0-9!@#\$%\^\&*\)\(+=.,_ -]+$/) ]],
    });

    this.oauth2Form = fb.group({
      scopes: [[],[Validators.pattern(/^[a-zA-Z0-9!@#\$%\^\&*\)\(+=.,_ -]+$/) ]],
      mandatoryScope: [false],
      tokenExpiration: [null,[Validators.pattern(/^[0-9]+$/)]],
      hideCredentials: [false],
      globalCredentials: [false],
      refreshTokenTtl: [0,[Validators.pattern(/^[0-9]+$/)]],
      reuseRefreshToken: [false],
      enabled:[true],
      name:[null, [Validators.required,Validators.pattern(/^[a-zA-Z0-9!@#\$%\^\&*\)\(+=.,_ -]+$/) ]],
      clientId:[null,[Validators.pattern(/^[a-zA-Z0-9!@#\$%\^\&*\)\(+=.,_ -]+$/) ]],
      clientSecret:[null,[Validators.pattern(/^[a-zA-Z0-9!@#\$%\^\&*\)\(+=.,_ -]+$/) ]],
    });

    this.jwtForm = fb.group({
      keyClaimName: [null,[Validators.pattern(/^[a-zA-Z0-9!@#\$%\^\&*\)\(+=.,_ -]+$/) ]],
      secretIsBase64: [null],
      claimsToVerify: [[],[Validators.pattern(/^[a-zA-Z0-9!@#\$%\^\&*\)\(+=.,_ -]+$/) ]],
      runOnPreflight: [null],
      maximumExpiration: [0,[Validators.pattern(/^[0-9]+$/)]],
      enabled:[true],
      secret:[null,[Validators.pattern(/^[a-zA-Z0-9!@#\$%\^\&*\)\(+=.,_ -]+$/) ]],
    });

    this.hmacAuthForm = fb.group({
      hideCredentials:[false],
      validateRequestBody: [false],
      enforceHeaders:[[],[Validators.pattern(/^[a-zA-Z0-9!@#\$%\^\&*\)\(+=.,_ -]+$/) ]],
      algorithms:[[],[Validators.pattern(/^[a-zA-Z0-9!@#\$%\^\&*\)\(+=.,_ -]+$/) ]],
      enabled:[true],
      secret:[null,[Validators.pattern(/^[a-zA-Z0-9!@#\$%\^\&*\)\(+=.,_ -]+$/) ]],
      username:[null, [Validators.required,Validators.pattern(/^[a-zA-Z0-9!@#\$%\^\&*\)\(+=.,_ -]+$/) ]],

    });

    this.shared.isLoading().subscribe((isLoading) => {
      this.isLoading = isLoading;
    });
   }

  ngOnInit(): void {
  }




  // Basic Auth Start //


  addBasicAuth(jsonData){
    let url = this.fromMenu=="services" && this.id ? this.basicAuthSaveUrl+'/service/'+this.id : this.fromMenu=="routes" && this.id?this.basicAuthSaveUrl+'/route/'+this.id: this.isCredentials && this.id?this.basicAuthSaveUrl+environment.path.api_management.consumers+"/"+this.id :this.basicAuthSaveUrl
    this.server.doPostWithEnvi(jsonData, url, this.envi).subscribe(async resp => {
      if (resp) {
        Swal.fire('Success!', resp['message'], 'success').then((result) => {
          if (result.isConfirmed) {
            this.hideModal()
            if(this.isCredentials){
              this.reloadDatatables.emit()
            }
          }
        });
      } else {
        Swal.fire('Failure!', resp['message'], 'error')
      }
    })
  }

  editBasicAuth(jsonData){
    let url = this.fromMenu=="services" && this.id ? this.basicAuthSaveUrl+'/'+this.pluginId+'/service/'+this.id : this.fromMenu=="routes" && this.id?this.basicAuthSaveUrl+'/'+this.pluginId+'/route/'+this.id: this.isCredentials && this.id?this.basicAuthSaveUrl+'/'+this.pluginId+'/consumer/'+this.id :this.basicAuthSaveUrl+'/'+this.pluginId
    this.server.doPutWithEnvi(jsonData, url, this.envi).subscribe(async resp => {
      if (resp) {
        Swal.fire('Success!', resp['message'], 'success').then((result) => {
          if (result.isConfirmed) {
            this.hideModal()
            if(this.isCredentials){
              this.reloadDatatables.emit()
            }
          }
        });
      } else {
        Swal.fire('Failure!', resp['message'], 'error')
      }
    })
  }


  submitBasicAuth() {
    let data = this.basicAuthForm.value
    let jsonData ={}
    if(this.isCredentials){
      jsonData = {
        username: data.username,
        password: data.password
      }
    }else{
      jsonData = {
        anonymous: data.anonymous,
        hideCredentials: data.credentials,
        enabled: data.enabled
      }
    }

    if(this.isEdit){
      this.editBasicAuth(jsonData)
    }else{
      this.addBasicAuth(jsonData)
    }
  }

  // Basic Auth End //


  // Key Auth Start //
  addKeyAuth(jsonData){
    let url = this.fromMenu=="services" && this.id ? this.keyAuthSaveUrl+'/service/'+this.id : this.fromMenu=="routes" && this.id?this.keyAuthSaveUrl+'/route/'+this.id: this.isCredentials && this.id?this.keyAuthSaveUrl+environment.path.api_management.consumers+"/"+this.id :this.keyAuthSaveUrl
    this.server.doPostWithEnvi(jsonData, url, this.envi).subscribe(async resp => {
      if (resp) {
        Swal.fire('Success!', resp['message'], 'success').then((result) => {
          if (result.isConfirmed) {
            if(this.isCredentials){
              this.reloadDatatables.emit()
            }
            this.hideModal()
          }
        });
      } else {
        Swal.fire('Failure!', resp['message'], 'error')
      }
    })
  }

  editKeyAuth(jsonData){
    let url = this.fromMenu=="services" && this.id ? this.keyAuthSaveUrl+'/'+this.pluginId+'/service/'+this.id : this.fromMenu=="routes" && this.id?this.keyAuthSaveUrl+'/'+this.pluginId+'/route/'+this.id: this.isCredentials && this.id?this.keyAuthSaveUrl+'/'+this.pluginId+environment.path.api_management.consumers+"/"+this.id :this.keyAuthSaveUrl+'/'+this.pluginId
    this.server.doPutWithEnvi(jsonData, url, this.envi).subscribe(async resp => {
      if (resp) {
        Swal.fire('Success!', resp['message'], 'success').then((result) => {
          if (result.isConfirmed) {
            if(this.isCredentials){
              this.reloadDatatables.emit()
            }
            this.hideModal()
          }
        });
      } else {
        Swal.fire('Failure!', resp['message'], 'error')
      }
    })
  }

  submitKeyAuth() {
    let data = this.keyAuthForm.value
    let jsonData ={}
    if(this.isCredentials){
      jsonData = {
        key: data.key,
      }
    }else{
      jsonData = {
        keyNames: data.keyNames ? data.keyNames.split(",") : [],
        keyInHeader: data.keyInHeader,
        keyInQuery: data.keyInQuery,
        keyInBody: data.keyInBody,
        enabled: data.enabled,
        hideCredentials: data.credentials,
        runOnPreflight: data.runOnPreflight
      }
    }

    if(!this.isEdit){
      this.addKeyAuth(jsonData)
    }else{
      this.editKeyAuth(jsonData)
    }
    
  }

  // Key Auth End //

  // Oauth2 Start //
  addOauth2(jsonData){
    let url = this.fromMenu=="services" && this.id ? this.oauth2SaveUrl+'/service/'+this.id : this.fromMenu=="routes" && this.id?this.oauth2SaveUrl+'/route/'+this.id: this.isCredentials && this.id?this.oauth2SaveUrl+environment.path.api_management.consumers+"/"+this.id :this.oauth2SaveUrl
      this.server.doPostWithEnvi(jsonData, url, this.envi).subscribe(async resp => {
        if (resp) {
          Swal.fire('Success!', resp['message'], 'success').then((result) => {
            if (result.isConfirmed) {
              if(this.isCredentials){
                this.reloadDatatables.emit()
              }
              this.hideModal()
            }
          });
        } else {
          Swal.fire('Failure!', resp['message'], 'error')
        }
      })
  }

  edeitOauth2(jsonData){
    let url = this.fromMenu=="services" && this.id ? this.oauth2SaveUrl+'/'+this.pluginId+'/service/'+this.id : this.fromMenu=="routes" && this.id?this.oauth2SaveUrl+'/'+this.pluginId+'/route/'+this.id: this.isCredentials && this.id?this.oauth2SaveUrl+'/'+this.pluginId+environment.path.api_management.consumers+"/"+this.id :this.oauth2SaveUrl+'/'+this.pluginId
      this.server.doPutWithEnvi(jsonData, url, this.envi).subscribe(async resp => {
        if (resp) {
          Swal.fire('Success!', resp['message'], 'success').then((result) => {
            if (result.isConfirmed) {
              if(this.isCredentials){
                this.reloadDatatables.emit()
              }
              this.hideModal()
            }
          });
        } else {
          Swal.fire('Failure!', resp['message'], 'error')
        }
      })
  }
  submitOauth2() {
    let data = this.oauth2Form.value
    if(data.mandatoryScope== true && (data.scopes == null|| data.scopes == "")){
      Swal.fire('Failure!', 'Scopes Tidak Boleh Kosong.', 'error')
    }else{
      let jsonData={}
      if(this.isCredentials){
        jsonData = {
          name: data.name,
          clientId: data.clientId,
          clientSecret: data.clientSecret,
          redirectUris: [],
        }
      }else{
        jsonData = {
          scopes: data.scopes.length > 0 ? data.scopes.split(",") : [],
          mandatoryScope: data.mandatoryScope,
          tokenExpiration: data.tokenExpiration,
          hideCredentials: data.hideCredentials,
          globalCredentials: data.globalCredentials,
          refreshTokenTtl: data.refreshTokenTtl,
          reuseRefreshToken: data.reuseRefreshToken,
          enabled:data.enabled
        }
      }
      if(!this.isEdit){
        this.addOauth2(jsonData)
      }else{
        this.edeitOauth2(jsonData)
      }
    }
  }

  // Oauth2 End //


  // HMAC Start //

  addHmacAuth(jsonData){
    let url = this.fromMenu=="services" && this.id ? this.hmacAuthSaveUrl+'/service/'+this.id : this.fromMenu=="routes" && this.id?this.hmacAuthSaveUrl+'/route/'+this.id: this.isCredentials && this.id?this.hmacAuthSaveUrl+environment.path.api_management.consumers+"/"+this.id :this.hmacAuthSaveUrl
    this.server.doPostWithEnvi(jsonData, url, this.envi).subscribe(async resp => {
      if (resp) {
        Swal.fire('Success!', resp['message'], 'success').then((result) => {
          if (result.isConfirmed) {
            if(this.isCredentials){
              this.reloadDatatables.emit()
            }
            this.hideModal()
          }
        });
      } else {
        Swal.fire('Failure!', resp['message'], 'error')
      }
    })
  }

  editHmacAuth(jsonData){
    let url = this.fromMenu=="services" && this.id ? this.hmacAuthSaveUrl+'/'+this.pluginId+'/service/'+this.id : this.fromMenu=="routes" && this.id?this.hmacAuthSaveUrl+'/'+this.pluginId+'/route/'+this.id: this.isCredentials && this.id?this.hmacAuthSaveUrl+'/'+this.pluginId+environment.path.api_management.consumers+"/"+this.id :this.hmacAuthSaveUrl+'/'+this.pluginId
    this.server.doPutWithEnvi(jsonData, url, this.envi).subscribe(async resp => {
      if (resp) {
        Swal.fire('Success!', resp['message'], 'success').then((result) => {
          if (result.isConfirmed) {
            if(this.isCredentials){
              this.reloadDatatables.emit()
            }
            this.hideModal()
          }
        });
      } else {
        Swal.fire('Failure!', resp['message'], 'error')
      }
    })
  }
  submitHmacAuth() {
    let data = this.hmacAuthForm.value
    let jsonData={}
    if(this.isCredentials){
      jsonData = {
        secret:data.secret,
        username:data.username
      }
    }else{
      jsonData = {
        enabled:data.enabled,
        hideCredentials:data.hideCredentials,
        validateRequestBody:data.validateRequestBody,
        enforceHeaders:data.enforceHeaders?data.enforceHeaders.split(","):[],
        algorithms:data.algorithms?data.algorithms.split(","):[]
      }
    }
    if(!this.isEdit){
      this.addHmacAuth(jsonData)
    }else{
      this.editHmacAuth(jsonData)
    }
  }

  // HMAC End //


 // JWT Start //
 
  addJwt(jsonData){
    let url = this.fromMenu=="services" && this.id ? this.jwtSaveUrl+'/service/'+this.id : this.fromMenu=="routes" && this.id?this.jwtSaveUrl+'/route/'+this.id: this.isCredentials && this.id?this.jwtSaveUrl+environment.path.api_management.consumers+"/"+this.id :this.jwtSaveUrl
    this.server.doPostWithEnvi(jsonData, url, this.envi).subscribe(async resp => {
      if (resp) {
        Swal.fire('Success!', resp['message'], 'success').then((result) => {
          if (result.isConfirmed) {
            if(this.isCredentials){
              this.reloadDatatables.emit()
            }
            this.hideModal()
          }
        });
      } else {
        Swal.fire('Failure!', resp['message'], 'error')
      }
    })
  }
  
  editJwt(jsonData){
    let url = this.fromMenu=="services" && this.id ? this.jwtSaveUrl+'/'+this.pluginId+'/service/'+this.id : this.fromMenu=="routes" && this.id?this.jwtSaveUrl+'/'+this.pluginId+'/route/'+this.id: this.isCredentials && this.id?this.jwtSaveUrl+'/'+this.pluginId+environment.path.api_management.consumers+"/"+this.id :this.jwtSaveUrl+'/'+this.pluginId
    this.server.doPutWithEnvi(jsonData, url, this.envi).subscribe(async resp => {
      if (resp) {
        Swal.fire('Success!', resp['message'], 'success').then((result) => {
          if (result.isConfirmed) {
            if(this.isCredentials){
              this.reloadDatatables.emit()
            }
            this.hideModal()
          }
        });
      } else {
        Swal.fire('Failure!', resp['message'], 'error')
      }
    })
  }
  submitJwt() {
    let data = this.jwtForm.value
    let jsonData ={}
    if(this.isCredentials){
      jsonData = {
        secret: data.secret,
      }
    }else{
      jsonData = {
        keyClaimName: data.keyClaimName,
        secretIsBase64: data.secretIsBase64,
        claimsToVerify: data.claimsToVerify?data.claimsToVerify.split(","):[],
        runOnPreflight: data.runOnPreflight,
        maximumExpiration: data.maximumExpiration,
        enabled: data.enabled
      }
    }

    if(!this.isEdit){
      this.addJwt(jsonData)
    }else{
      this.editJwt(jsonData)
    }

    
  }
   // JWT End //

  get v(){
    return this.oauth2Form.controls
  }
  onClickMandatroryScope(event){
    if (event.target.checked==true){
      this.oauth2Form.controls.scopes.addValidators(Validators.required)
    }else{
      this.oauth2Form.controls.scopes.clearValidators()
    }
    this.oauth2Form.controls.scopes.updateValueAndValidity()
  }


  fetchDataModal(key, data){
    this.id= data.service ? data.service.id : data.route ? data.route.id : data.consumer ? data.consumer.id : null
    this.pluginId= data.id
    switch (key) {
      case 'basicAuth':
          if(this.isCredentials){
            this.basicAuthForm.controls.username.setValue(data.username)
            this.basicAuthForm.controls.password.setValue(data.password)
          }else{
            this.basicAuthForm.controls.anonymous.setValue(data.config.anonymous)
            this.basicAuthForm.controls.credentials.setValue(data.config.hideCredentials)
            this.basicAuthForm.controls.enabled.setValue(data.enabled)
          }
        break;
      case 'keyAuth':
        this.keyAuthForm.controls.keyNames.setValue(data.config.keyNames?data.config.keyNames.join(','):null)
        this.keyAuthForm.controls.credentials.setValue(data.config.hideCredentials)
        this.keyAuthForm.controls.anonymous.setValue(data.config.anonymous)
        this.keyAuthForm.controls.keyInHeader.setValue(data.config.keyInHeader)
        this.keyAuthForm.controls.keyInQuery.setValue(data.config.keyInQuery)
        this.keyAuthForm.controls.keyInBody.setValue(data.config.keyInBody)
        this.keyAuthForm.controls.runOnPreflight.setValue(data.config.runOnPreflight)
        this.keyAuthForm.controls.enabled.setValue(data.enabled)
     
        break;
      case 'oauth2':
        this.oauth2Form.controls.scopes.setValue(data.config.scopes?data.config.scopes.join(','):null)
        this.oauth2Form.controls.mandatoryScope.setValue(data.config.mandatoryScope)
        this.oauth2Form.controls.tokenExpiration.setValue(data.config.tokenExpiration)
        this.oauth2Form.controls.hideCredentials.setValue(data.config.hideCredentials)
        this.oauth2Form.controls.globalCredentials.setValue(data.config.globalCredentials)
        this.oauth2Form.controls.refreshTokenTtl.setValue(data.config.refreshTokenTtl)
        this.oauth2Form.controls.reuseRefreshToken.setValue(data.config.reuseRefreshToken)
        this.oauth2Form.controls.enabled.setValue(data.enabled)
        
        break;
      case 'hmacAuth':

        this.hmacAuthForm.controls.hideCredentials.setValue(data.config.hideCredentials)
        this.hmacAuthForm.controls.validateRequestBody.setValue(data.config.validateRequestBody)
        this.hmacAuthForm.controls.enforceHeaders.setValue(data.config.enforceHeaders?data.config.enforceHeaders.join(','):null)
        this.hmacAuthForm.controls.algorithms.setValue(data.config.algorithms?data.config.algorithms.join(','):null)
        this.hmacAuthForm.controls.enabled.setValue(data.enabled)
        this.hmacAuthForm.controls.secret.setValue(data.config.secret)
        this.hmacAuthForm.controls.username.setValue(data.config.username)
    
      break;
      case 'jwt':
        this.jwtForm.controls.keyClaimName.setValue(data.config.keyClaimName)
        this.jwtForm.controls.secretIsBase64.setValue(data.config.secretIsBase64)
        this.jwtForm.controls.claimsToVerify.setValue(data.config.claimsToVerify?data.config.claimsToVerify.join(','):null)
        this.jwtForm.controls.runOnPreflight.setValue(data.config.runOnPreflight)
        this.jwtForm.controls.maximumExpiration.setValue(+data.config.maximumExpiration)
        this.jwtForm.controls.enabled.setValue(data.enabled)
        this.jwtForm.controls.secret.setValue(data.config.secret)

        break;
      case 'ldapAuth':

        break;
      case 'session':

        break;
      default:
        console.log("No key Choosen!!!");
        break;
    }
  }


  showModalAndSetForm(key){
    switch (key) {
      case 'basicAuth':
        if(!this.isEdit){
          this.basicAuthForm.controls.enabled.setValue(true)
        }
        if(this.isCredentials){
          this.basicAuthForm.controls.username.addValidators(Validators.required)
        }else{
          this.basicAuthForm.controls.username.clearValidators()
        }
        this.basicAuthForm.controls.username.updateValueAndValidity()
        this.basicAuthModal.show()
        break;
      case 'keyAuth':
        if(!this.isEdit){
          this.keyAuthForm.controls.keyInHeader.setValue(true)
          this.keyAuthForm.controls.keyInQuery.setValue(true)
          this.keyAuthForm.controls.runOnPreflight.setValue(true)
          this.keyAuthForm.controls.enabled.setValue(true)
        }
        this.keyAuthModal.show()
        break;
      case 'oauth2':
        if(this.isCredentials){
          this.oauth2Form.controls.name.addValidators(Validators.required)
        }else{
          if(!this.isEdit){
            this.oauth2Form.controls.enabled.setValue(true)
          }
          this.oauth2Form.controls.name.clearValidators()
        }
        this.oauth2Form.controls.name.updateValueAndValidity()
        this.oauth2Modal.show()
        break;
      case 'hmacAuth':
        if(this.isCredentials){
          this.hmacAuthForm.controls.username.addValidators(Validators.required)
        }else{
          this.hmacAuthForm.controls.username.clearValidators()
        }
        this.hmacAuthForm.controls.username.updateValueAndValidity()

        
        this.hmacAuthModal.show()
      break;
      case 'jwt':
        if(!this.isCredentials && !this.isEdit) {
          this.jwtForm.controls.keyClaimName.setValue('iss')
          this.jwtForm.controls.maximumExpiration.setValue(0)
          this.jwtForm.controls.enabled.setValue(true)
          this.jwtForm.controls.runOnPreflight.setValue(true)
          this.jwtForm.controls.secretIsBase64.setValue(false)
        }
        this.jwtModal.show()
        break;
      case 'ldapAuth':

        break;
      case 'session':

        break;
      default:
        console.log("No key Choosen!!!");
        break;
    }
  }

  showAddModal(key, data) {
    this.key = key
    if(data!=null){
      this.isEdit=true
      this.tittle='UBAH'
      this.fetchDataModal(key,data)
    }else{
      this.tittle='TAMBAH'
    }
    this.showModalAndSetForm(key)
  }




  hideModal() {
    if(!this.isEdit){
      this.showModalPlugin.emit()
    }else{
      this.reloadDatatables.emit()
    }
    this.isEdit=false
    switch (this.key) {
      case 'basicAuth':
        this.basicAuthModal.hide()
        this.basicAuthForm.reset()
        break;
      case 'keyAuth':
        this.keyAuthModal.hide()
        this.keyAuthForm.reset()
        break;
      case 'oauth2':
          this.oauth2Modal.hide()
          this.oauth2Form.reset()
      break;
      case 'hmacAuth':
          this.hmacAuthModal.hide()
          this.hmacAuthForm.reset()
      break;
      case 'jwt':
        this.jwtModal.hide()
        this.jwtForm.reset()
        break;
      case 'ldapAuth':

        break;
      case 'session':

        break;
      default:
        console.log("No key Choosen!!!");
        break;
    }
  }

  submitModal() {
    switch (this.key) {
      case 'basicAuth':
        this.submitBasicAuth()
        break;
      case 'keyAuth':
        this.submitKeyAuth()
        break;
      case 'oauth2':
        this.submitOauth2()
        break;
      case 'hmacAuth':
        this.submitHmacAuth()
        break;
      case 'jwt':
        this.submitJwt()
        break;
      case 'ldapAuth':

        break;
      case 'session':

        break;
      default:
        console.log("No key Choosen!!!");
        break;
    }

  }

}
