// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "Your Api Key",
    authDomain: "Your Auth Domain",
    databaseURL: "Your Database Url",
    projectId: "Your Project Id",
    storageBucket: "Your StorageBucket url",
    messagingSenderId: "Your Sender Id"
  },
  tenant:"Bank Kalbar",
  logo:"assets/images/logo/logo.png",
  logo_icon:"assets/images/logo/logo-icon.png",
  primary_color:'#0494dc',
  secondary_color: '#049544',
  isChangeImageLogin:false,
  isGeneratedKey:true,
  path:{
    base:"https://api.snap.bankkalbar.co.id",
    bank:{
      base:"/bank",
      module:"/module",
      apidev:"/apidev",
      datatable:"/datatable",
      group_user:"/group-user",
      user:"/user",
      select2:"/select2",
      group_approval:"/group-approval",
      group_approval_app:"/group-approval-application",
      group_approval_path:"/group-approval-path",
      company_partner:"/company-partner",
      acc_company_partner:"/account-company-partner",
      partner:"/partner",
      approval:"/approval",
      approval_application:"/approval-application",
      approval_path:"/approval-path",
      approval_user:"/approval-user",
      application_partner:"/application-partner",
      profile:"/profile",
      bankDashboard:"/bankdashboard",
      companyDashboard:"/by-company",
      serviceDashboard:"/by-service",
      applicationDashboard:"/by-application",
      fileType:"/file-type",
      business_criteria:"/business-criteria",
      business_category:"/business-category",
      merchant_category_code:"/merchant-category-code",
      registration_partner:"/registration-partner",
      company_file_type:"/company-file-type",
      channel:"/channel",
      ipWhitelist:"/ip-whitelist",
      log_activity:"/log-activity",
      user_session:"/user-session",
      terminate_user_session:"/terminate-user-session",
      national_merchat_id:"/national-merchant-id",
      email_whitelist: "/email-whitelist",
      group_approval_user: "/group-approval-user",
      forgot_password: "/forgot-password",
      reset_password: "/reset-password",
      mx_messalge_log: "/mx-message-log"
    },
    api_dev:{
      base:"/apidev",
      datatable:"/datatable",
      select2:"/select2",
      requestBody:"/request-body",
      path:"/path",
      groupPath:"/group-path",
      requestBodyRule:"/request-body-rule",
      iso_req:"/iso-request",
      request_body:"/request-body",
      message_generator:"/message-generator",
      iso_resp:"/iso-response",
      request_header:"/request-header",
      response_body:"/response-body",
      coreProgram: "/core-program",
      coreProgramParam: "/core-program-param",
      dxProcode:"/dx-procode",
      externalPath:"/external-path",
      externalPathLog:"/external-path-log",
      externalRequestBody: "/external-request-body",
      externalResponseBody: "/external-response-body",
      externalRequestHeader: "/external-request-header",
      externalQuesryString: "/external-query-string",
      requestPayload:"/request-payload",
      responsePayload:"/response-payload",
      playground:"/playground",
      revenue:"/revenue",
      settlement:"/settlement",
      reconcile:"/reconcile",
      report_log:"/report-log",
      mx_config_file:"/mx-config-file",
      procode:"/procode",
      query_string:"/query-string",
      error_response_mapping:"/error-response-mapping",
      path_tscen_document:"/path-tscen-doc",
      group_dependency:"/group-dependency",
      published:"/published"
    },
    api_management:{
      base:"/apimanagement",
      consumers:"/consumer",
      plugin:"/plugin",
      find_all:"/find-all",
      routes:"/routes",
      route:"/route",
      find_by_id:"/find-by-id",
      services:"/service",
      service:"/service",
      delete:"/delete",
      save:"/save",
      update:"/update",
      consumer_group:"/consumer-group",
      basicAuth:"/basic-auth",
      keyAuth:"/key-auth",
      oauth2:"/oauth2",
      hmacAuth:"/hmac-auth",
      jwtAuth:"/jwt-auth",
      cors:"/cors",
      acl:"/acl",
      ip_restriction:"/ip-restriction",
      request_transformer:"/request-transformer",
      telkomsigma_response_transformer:"/telkomsigma-response-transformer",
      request_termination:"/request-termination",
      sigmaHttpLog:"/telkomsigma-http-log",
      sigmaHmacAuth:"/telkomsigma-hmac-auth",
      company_partner_management:"/company-partner-management",
      bot_detection:"/bot-detection",
      rate_limiting:"/rate-limiting",

    },
    fee_management:{
      base: "/feemanagement",
      general_setting: "/general-setting",
      invoice:"/invoice",
      invoice_log:"/invoice-log",
      mitra: "/mitra",
      pic:"/pic",
      company_partner:"/company-partner",
      partner_path:"/partner-path",
      fee_reminder:"/fee-reminder",
      report_configuration:"/report-configuration"
    },
    versioning: "/versioning",
    publish: "/publish"
  }
};

export const REQUEST_BODY = environment.path.base + environment.path.api_dev.base + environment.path.api_dev.requestBody
export const REQUEST_BODY_SELECT2 = environment.path.base + environment.path.api_dev.base + environment.path.api_dev.requestBody + environment.path.api_dev.select2
export const REQUEST_BODY_RULE = environment.path.base + environment.path.api_dev.base + environment.path.api_dev.requestBodyRule
export const PATH_SELECT2 = environment.path.base + environment.path.api_dev.base + environment.path.api_dev.path + environment.path.api_dev.select2
export const GROUP_PATH_SELECT2 = environment.path.base + environment.path.api_dev.base + environment.path.api_dev.groupPath + environment.path.api_dev.select2
export const SAVE_WIZARD_PATH = environment.path.base + environment.path.api_dev.base + environment.path.api_dev.path+"/wizard"
export const RESPONSE_BODY = environment.path.base + environment.path.api_dev.base + environment.path.api_dev.response_body
export const PROFILE = environment.path.base + environment.path.bank.base + environment.path.bank.profile
export const DASHBOARD_BY_COMPANY =  environment.path.base + environment.path.bank.bankDashboard + environment.path.bank.companyDashboard
export const DASHBOARD_BY_SERVICE =  environment.path.base + environment.path.bank.bankDashboard + environment.path.bank.serviceDashboard
export const FILE_TYPE = environment.path.base + environment.path.bank.base + environment.path.bank.fileType

export const CORE_PROGRAM = environment.path.base + environment.path.api_dev.base + environment.path.api_dev.coreProgram + environment.path.api_dev.select2
export const CORE_PROGRAM_PARAM = environment.path.base + environment.path.api_dev.base + environment.path.api_dev.coreProgramParam + environment.path.api_dev.select2

export const PLUGINS = environment.path.base + environment.path.api_management.base + environment.path.api_management.plugin
export const ROUTES = environment.path.base + environment.path.api_management.base + environment.path.api_management.routes
export const SERVICES = environment.path.base + environment.path.api_management.base + environment.path.api_management.services
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
