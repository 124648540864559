import { Component, OnInit, ViewChild, Input, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NgbDropdownConfig, NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { SharedService } from 'src/app/components/development/shared.service';
import { ServerService } from 'src/app/service/server.service';

declare var require
const Swal = require('sweetalert2')

@Component({
  selector: 'app-datatable-newest',
  templateUrl: './datatable-newest.component.html',
  styleUrls: ['./datatable-newest.component.scss']
})
export class DatatableNewestComponent implements OnInit { 
  // Inisialisasi variabel  
  sanitizedHtml: SafeHtml;
  @ViewChild( DataTableDirective, { static: false })
  dtElement : DataTableDirective;
  dtOptions : DataTables.Settings;

  @Input() columns=[]
  @Input() order=[]
  @Input() dataTableForm: FormGroup;
  @Input() url: any;
  @Input() isDelete: boolean =false;
  @Input() isEdit: boolean =false;
  @Input() isDetail: boolean =false;
  @Input() isPlugin: boolean =false;
  @Input() isConsumer: boolean =false;


  @Output() onDelete= new EventEmitter();
  @Output() onEdit= new EventEmitter();
  @Output() onDetail= new EventEmitter();
  @Output() onEditPlugin= new EventEmitter();
  @Output() onLoadData= new EventEmitter();
  // @Output ("reloadDatatables") fetchDataTable: EventEmitter<any> = new EventEmitter();


  constructor(config: NgbDropdownConfig,
    config1: NgbModalConfig,
    private modalService: NgbModal,
    private server: ServerService,
    private cdref: ChangeDetectorRef,
    private fb: FormBuilder,
    private shared: SharedService,
    private sanitizer: DomSanitizer,) {
      // customize default values of dropdowns used by this component tree
    config.placement = 'top-left';
    config.autoClose = false;
    config1.backdrop = 'static';
    config1.keyboard = false;
    }

  ngOnInit(): void {
    if(this.isPlugin){
      this.loadDatatablesV2()
    }else{
      this.loadDatatables()
    }
  }

  loadDatatables() {
    this.dtOptions = {
      dom: '<"pull-right"l>t<ip>',
      pagingType : 'full_numbers',
      pageLength : 10,
      serverSide : true,
      processing : true,
      responsive : true,
      ajax : ( dataTablesParameters : any, callback ) => {
        let envi = this.dataTableForm.value.envi?this.dataTableForm.value.envi:""
        this.server.doPostWithEnvi( dataTablesParameters, this.url,envi).subscribe(
          ( response : { data : any; recodsTotal : any; recordsFiltered : any }) => {         
            this.sanitizedHtml = this.sanitizer.bypassSecurityTrustHtml(response.data);
            // Ambil string HTML yang disaring kemudian lakukan map untuk menghilangkan data yg ada tag HTML nya 
            const sanitizedData = this.sanitizedHtml['changingThisBreaksApplicationSecurity'].data.map(item => ({
                ...item,
                name: item.name ? this.shared.stripHtmlTags(item.name):'',
            }));
            callback(
              {
                recordsTotal: this.sanitizedHtml['changingThisBreaksApplicationSecurity'].recordsTotal,
                recordsFiltered: this.sanitizedHtml['changingThisBreaksApplicationSecurity'].recordsFiltered,
                data: sanitizedData,
              }
            );
          }, (error) => {
          }
        );
      },
      columns:this.columns,
      order : this.order? this.order:[4, 'desc'],
      rowCallback : ((nRow, data, index) => {
        const eventDetail = $('td', nRow).find('span.path-dtl');
        const eventEdit = $('td', nRow).find('span.path-edit');
        const eventDelete = $('td', nRow).find('span.path-delete');
        const eventPluginEdit = $('td', nRow).find('a.edit-plugin');


        if(!this.isDetail){
          eventDetail.hide()
        }
        if(!this.isEdit){
          eventEdit.hide()
        }
        if(!this.isDelete){
          eventDelete.hide()
        }

      
        eventDetail.off('click').on('click', () => {
          this.onDetail.emit(data)
        });
        eventEdit.off('click').on('click', () => {
          this.onEdit.emit(data)
        });
        eventDelete.off('click').on('click', () => {
          this.onDelete.emit(data)
        });

        eventPluginEdit.off('click').on('click', () => {
          this.onEditPlugin.emit(data)
        });
        
      })
    }
  }

  loadDatatablesV2() {
    this.dtOptions = {
      dom: '<"pull-right"l>t<ip>',
      pagingType : 'full_numbers',
      pageLength : 10,
      // serverSide : true,
      serverSide: false,
      processing : true,
      responsive : true,
      ajax: (param: any, callback) => {
        let envi = this.dataTableForm.value.envi?this.dataTableForm.value.envi:""
        this.server.doGetWithEnvi(this.url,envi).subscribe((response) => {
          this.sanitizedHtml = this.sanitizer.bypassSecurityTrustHtml(response.data);
            // Ambil string HTML yang disaring kemudian lakukan map untuk menghilangkan data yg ada tag HTML nya 
            const sanitizedData = this.sanitizedHtml['changingThisBreaksApplicationSecurity'].map(item => ({
                ...item,
                name: item.name ? this.shared.stripHtmlTags(item.name):'',
            }));
            // console.log("dataSanitize = "+JSON.stringify(sanitizedData))
            callback({
              data: sanitizedData
            });
        });
      },
      columns:this.columns,
      order : this.order? this.order:[4, 'desc'],
      rowCallback : ((nRow, data, index) => {
        const eventDetail = $('td', nRow).find('span.path-dtl');
        const eventEdit = $('td', nRow).find('span.path-edit');
        const eventDelete = $('td', nRow).find('span.path-delete');
        const eventPluginEdit = $('td', nRow).find('a.edit-plugin');


        if(!this.isDetail){
          eventDetail.hide()
        }
        if(!this.isEdit){
          eventEdit.hide()
        }
        if(!this.isDelete){
          eventDelete.hide()
        }

        if(this.isConsumer && this.dataTableForm.value.envi!=='internal' && this.dataTableForm.value.envi!=='playground'){
          eventDelete.hide()
        }


        eventDetail.off('click').on('click', () => {
          this.onDetail.emit(data)
        });
        eventEdit.off('click').on('click', () => {
          this.onEdit.emit(data)
        });
        eventDelete.off('click').on('click', () => {
          this.onDelete.emit(data)
        });


        eventPluginEdit.off('click').on('click', () => {
          this.onEditPlugin.emit(data)
        });
        
      })
    }
  }

  reloadDatatables() {
    this.dtElement.dtInstance.then( ( dtInstance : DataTables.Api ) => {
      dtInstance.column(0).search("").draw();
      dtInstance.ajax.reload();
    });
  }


  searchModal() {
    if (this.dtElement) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.columns(0).search(this.dataTableForm.controls.name.value == null ? "" : this.dataTableForm.controls.name.value);

        dtInstance.draw();
        this.dataTableForm.controls.name.setValue(null)
        this.modalService.dismissAll()
      });
    }

  }

}
