<!-- Basic Auth Start -->
<div bsModal #basicAuthModal="bs-modal" class="modal fade" id="basicAuthModal" backdrop="static" keyboard="false"
    tabindex="-1" role="dialog" aria-labelledby="basicAuthModalLabel" aria-hidden="true">
    <form [formGroup]="basicAuthForm" (ngSubmit)="submitModal()">
        <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="modal-basic-title">{{tittle}} BASIC AUTH</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="hideModal()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <hr>
                <!-- <div class="panel-info">
                    <a>Add Basic Authentication to your APIs, with username and password protection. The plugin will check for valid credentials in the Proxy-Authorization and Authorization header (in this order).</a>
                </div> -->
                <div *ngIf="!isCredentials" class="modal-body">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group row">
                                <label class="col-sm-3 col-form-label">Anonymous</label>
                                <div class="col-sm-9">
                                    <textarea class="form-control" formControlName="anonymous" id="anonymous">
                                    </textarea>
                                    <span *ngIf="basicAuthForm.controls.anonymous.invalid && basicAuthForm.controls.anonymous.errors && (basicAuthForm.controls.anonymous.dirty || basicAuthForm.controls.anonymous.touched)">
                                        <small class="text-danger" *ngIf="basicAuthForm.controls.anonymous.hasError('pattern')"> Only letters, numbers, and special characters !@#$%^&*()_+-= are allowed.</small>
                                    </span>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-3 col-form-label">Hide Credentials</label>
                                <div class="col-sm-9">
                                    <div class="media-body text-end icon-state switch-outline">
                                        <label class="switch">
                                            <input type="checkbox" checked="" formControlName="credentials"><span
                                                class="switch-state bg-primary"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-3 col-form-label">Enabled</label>
                                <div class="col-sm-9">
                                    <div class="media-body text-end icon-state switch-outline">
                                        <label class="switch">
                                            <input type="checkbox" checked="" formControlName="enabled"><span
                                                class="switch-state bg-primary"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="isCredentials" class="modal-body">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group row">
                                <label class="col-sm-3 col-form-label">username</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control p-input" placeholder="username" 
                                        id="email" name="email" formControlName="username"
                                        [ngClass]="{ 'is-valid' : basicAuthForm.controls.username.valid , 'is-invalid' : basicAuthForm.controls.username.invalid }">
                                        <span *ngIf="basicAuthForm.controls.username.invalid && basicAuthForm.controls.username.errors && (basicAuthForm.controls.username.dirty || basicAuthForm.controls.username.touched)">
                                            <small class="text-danger" *ngIf="basicAuthForm.controls.username.hasError('pattern')"> Only letters, numbers, and special characters !@#$%^&*()_+-= are allowed.</small>
                                        </span>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-3 col-form-label">password</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control p-input" placeholder="password" 
                                        id="email" name="email" formControlName="password">
                                        <span *ngIf="basicAuthForm.controls.password.invalid && basicAuthForm.controls.password.errors && (basicAuthForm.controls.password.dirty || basicAuthForm.controls.password.touched)">
                                            <small class="text-danger" *ngIf="basicAuthForm.controls.password.hasError('pattern')"> Only letters, numbers, and special characters !@#$%^&*()_+-= are allowed.</small>
                                        </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="submit" class="btn btn-success" [disabled]="basicAuthForm.invalid||isLoading">Simpan</button>&nbsp;
                    <button type="button" class="btn btn-danger" (click)="hideModal()">Keluar</button>
                </div>
            </div>
        </div>
    </form>
</div>

<!-- Basic Auth End -->


<!-- Key Auth Start -->
<div bsModal #keyAuthModal="bs-modal" class="modal fade" id="keyAuthModal" backdrop="static" keyboard="false"
    tabindex="-1" role="dialog" aria-labelledby="keyAuthModalLabel" aria-hidden="true">
    <form [formGroup]="keyAuthForm" (ngSubmit)="submitModal()">
        <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="modal-basic-title">{{tittle}} KEY AUTH</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="hideModal()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <hr>
                <!-- <div class="panel-info">
                    <a>Add Basic Authentication to your APIs, with username and password protection. The plugin will check for valid credentials in the Proxy-Authorization and Authorization header (in this order).</a>
                </div> -->
                <div class="modal-body">
                    <div *ngIf="!isCredentials" class="row">
                        <div class="col-md-12">
                            <div class="form-group row">
                                <label class="col-sm-3 col-form-label">key names</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control p-input" placeholder="key names" 
                                        id="email" name="email" formControlName="keyNames">
                                    <small>Gunakan koma tanpa spasi sebagai pemisah jika mengisi lebih dari 1 key names</small>
                                    <span *ngIf="keyAuthForm.controls.keyNames.invalid && keyAuthForm.controls.keyNames.errors && (keyAuthForm.controls.keyNames.dirty || keyAuthForm.controls.keyNames.touched)">
                                        <small class="text-danger" *ngIf="keyAuthForm.controls.keyNames.hasError('pattern')"> Only letters, numbers, and special characters !@#$%^&*()_+-= are allowed.</small>
                                    </span>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-3 col-form-label">hide Credentials</label>
                                <div class="col-sm-9">
                                    <div class="media-body text-end icon-state switch-outline">
                                        <label class="switch">
                                            <input type="checkbox" checked="" formControlName="credentials"><span
                                                class="switch-state bg-primary"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-3 col-form-label">key in header</label>
                                <div class="col-sm-9">
                                    <div class="media-body text-end icon-state switch-outline">
                                        <label class="switch">
                                            <input type="checkbox" checked="" formControlName="keyInHeader"><span
                                                class="switch-state bg-primary"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-3 col-form-label">key in query</label>
                                <div class="col-sm-9">
                                    <div class="media-body text-end icon-state switch-outline">
                                        <label class="switch">
                                            <input type="checkbox" checked="" formControlName="keyInQuery"><span
                                                class="switch-state bg-primary"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-3 col-form-label">key in body</label>
                                <div class="col-sm-9">
                                    <div class="media-body text-end icon-state switch-outline">
                                        <label class="switch">
                                            <input type="checkbox" checked="" formControlName="keyInBody"><span
                                                class="switch-state bg-primary"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-3 col-form-label">run on preflight</label>
                                <div class="col-sm-9">
                                    <div class="media-body text-end icon-state switch-outline">
                                        <label class="switch">
                                            <input type="checkbox" checked="" formControlName="runOnPreflight"><span
                                                class="switch-state bg-primary"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-3 col-form-label">Enabled</label>
                                <div class="col-sm-9">
                                    <div class="media-body text-end icon-state switch-outline">
                                        <label class="switch">
                                            <input type="checkbox" checked="" formControlName="enabled"><span
                                                class="switch-state bg-primary"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="isCredentials" class="row">
                        <div class="col-md-12">
                            <div class="form-group row">
                                <label class="col-sm-3 col-form-label">key</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control p-input" placeholder="key" 
                                        id="email" name="email" formControlName="key">
                                        <span *ngIf="keyAuthForm.controls.key.invalid && keyAuthForm.controls.key.errors && (keyAuthForm.controls.key.dirty || keyAuthForm.controls.key.touched)">
                                            <small class="text-danger" *ngIf="keyAuthForm.controls.key.hasError('pattern')"> Only letters, numbers, and special characters !@#$%^&*()_+-= are allowed.</small>
                                        </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="submit" class="btn btn-success" [disabled]="isLoading||keyAuthForm.invalid">Simpan</button>&nbsp;
                    <button type="button" class="btn btn-danger" (click)="hideModal()">Keluar</button>
                </div>
            </div>
        </div>
    </form>
</div>

<!-- Key Auth End -->



<!-- Oauth2 Start -->
<div bsModal #oauth2Modal="bs-modal" class="modal fade" id="keyAuthModal" backdrop="static" keyboard="false"
    tabindex="-1" role="dialog" aria-labelledby="oauth2ModalLabel" aria-hidden="true">
    <form [formGroup]="oauth2Form" (ngSubmit)="submitModal()">
        <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="modal-basic-title">{{tittle}} OAUTH2</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="hideModal()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <hr>
                <!-- <div class="panel-info">
                    <a>Add Basic Authentication to your APIs, with username and password protection. The plugin will check for valid credentials in the Proxy-Authorization and Authorization header (in this order).</a>
                </div> -->
                <div class="modal-body">
                    <div *ngIf="!isCredentials" class="row">
                        <div class="col-md-12">
                            <div class="form-group row">
                                <label class="col-sm-4 col-form-label">mandatory scope</label>
                                <div class="col-sm-8">
                                    <div class="media-body text-end icon-state switch-outline">
                                        <label class="switch">
                                            <input type="checkbox" checked="" formControlName="mandatoryScope"><span
                                                class="switch-state bg-primary"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-4 col-form-label">scopes</label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control p-input" placeholder="scopes"
                                        id="email" name="email" formControlName="scopes">
                                    <small>Gunakan koma tanpa spasi sebagai pemisah jika mengisi lebih dari 1 key names</small>
                                    <span *ngIf="oauth2Form.controls.scopes.invalid && oauth2Form.controls.scopes.errors && (oauth2Form.controls.scopes.dirty || oauth2Form.controls.scopes.touched)">
                                        <small class="text-danger" *ngIf="oauth2Form.controls.scopes.hasError('pattern')"> Only letters, numbers, and special characters !@#$%^&*()_+-= are allowed.</small>
                                    </span>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-4 col-form-label">token expiration</label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control p-input" placeholder="token expiration"
                                         id="tokenExpiration" name="tokenExpiration" formControlName="tokenExpiration">
                                        <span *ngIf="oauth2Form.controls.tokenExpiration.invalid && oauth2Form.controls.tokenExpiration.errors && (oauth2Form.controls.tokenExpiration.dirty || oauth2Form.controls.tokenExpiration.touched)">
                                            <small class="text-danger" *ngIf="oauth2Form.controls.tokenExpiration.hasError('pattern')"> Only numbers, the characters is not allowed.</small>    
                                        </span>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-4 col-form-label">hide credentials</label>
                                <div class="col-sm-8">
                                    <div class="media-body text-end icon-state switch-outline">
                                        <label class="switch">
                                            <input type="checkbox" checked="" formControlName="hideCredentials"><span
                                                class="switch-state bg-primary"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-4 col-form-label">global credentials</label>
                                <div class="col-sm-8">
                                    <div class="media-body text-end icon-state switch-outline">
                                        <label class="switch">
                                            <input type="checkbox" checked="" formControlName="globalCredentials"><span
                                                class="switch-state bg-primary"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-4 col-form-label">refresh token ttl</label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control p-input" placeholder="refresh token ttl"
                                         id="email" name="email" formControlName="refreshTokenTtl">
                                        <small>Refresh token jika ingin tidak ada batasan waktu di isi dengan 0.</small>
                                        <br>
                                        <span *ngIf="oauth2Form.controls.refreshTokenTtl.invalid && oauth2Form.controls.refreshTokenTtl.errors && (oauth2Form.controls.refreshTokenTtl.dirty || oauth2Form.controls.refreshTokenTtl.touched)">
                                            <small class="text-danger" *ngIf="oauth2Form.controls.refreshTokenTtl.hasError('pattern')"> Only numbers, the characters is not allowed.</small>    
                                        </span>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-4 col-form-label">reuse refresh token</label>
                                <div class="col-sm-8">
                                    <div class="media-body text-end icon-state switch-outline">
                                        <label class="switch">
                                            <input type="checkbox" checked="" formControlName="reuseRefreshToken"><span
                                                class="switch-state bg-primary"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-4 col-form-label">Enabled</label>
                                <div class="col-sm-8">
                                    <div class="media-body text-end icon-state switch-outline">
                                        <label class="switch">
                                            <input type="checkbox" checked="" formControlName="enabled"><span
                                                class="switch-state bg-primary"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- iscredentials -->
                    <div *ngIf="isCredentials" class="row">
                        <div class="col-md-12">
                            <div class="form-group row">
                                <label class="col-sm-4 col-form-label">name</label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control p-input" placeholder="name" id="name" name="name" formControlName="name"
                                            [ngClass]="{ 'is-valid' : oauth2Form.controls.name.valid , 'is-invalid' : oauth2Form.controls.name.invalid }">
                                            <span *ngIf="oauth2Form.controls.name.invalid && oauth2Form.controls.name.errors && (oauth2Form.controls.name.dirty || oauth2Form.controls.name.touched)">
                                                <small class="text-danger" *ngIf="oauth2Form.controls.name.hasError('pattern')"> Only letters, numbers, and special characters !@#$%^&*()_+-= are allowed.</small>
                                            </span>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-4 col-form-label">client_id</label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control p-input" placeholder="client_id"
                                        id="clientId" name="clientId" formControlName="clientId">
                                        <span *ngIf="oauth2Form.controls.clientId.invalid && oauth2Form.controls.clientId.errors && (oauth2Form.controls.clientId.dirty || oauth2Form.controls.clientId.touched)">
                                            <small class="text-danger" *ngIf="oauth2Form.controls.clientId.hasError('pattern')"> Only letters, numbers, and special characters !@#$%^&*()_+-= are allowed.</small>
                                        </span>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-4 col-form-label">client_secret</label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control p-input" placeholder="client_secret"
                                        id="clientSecret" name="clientSecret" formControlName="clientSecret">
                                        <span *ngIf="oauth2Form.controls.clientSecret.invalid && oauth2Form.controls.clientSecret.errors && (oauth2Form.controls.clientSecret.dirty || oauth2Form.controls.clientSecret.touched)">
                                            <small class="text-danger" *ngIf="oauth2Form.controls.clientSecret.hasError('pattern')"> Only letters, numbers, and special characters !@#$%^&*()_+-= are allowed.</small>
                                        </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="submit" class="btn btn-success" [disabled]="oauth2Form.invalid||isLoading">Simpan</button>&nbsp;
                    <button type="button" class="btn btn-danger" (click)="hideModal()">Keluar</button>
                </div>
            </div>
        </div>
    </form>
</div>
<!-- Oauth2 End -->

<!-- Hmac Auth Start -->
<div bsModal #hmacAuthModal="bs-modal" class="modal fade" id="keyAuthModal" backdrop="static" keyboard="false" tabindex="-1" role="dialog" aria-labelledby="hmacAuthModalLabel" aria-hidden="true">
    <form [formGroup]="hmacAuthForm" (ngSubmit)="submitModal()">
        <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document" >
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="modal-basic-title">{{tittle}} HMAC AUTH</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="hideModal()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <hr>
                <div class="modal-body">
                    <div *ngIf="!isCredentials" class="row">
                        <div class="col-md-12">
                            <!-- <div class="form-group row">
                                <label class="col-sm-3 col-form-label">consumer</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control p-input" placeholder="consumer"
                                        id="parent" name="parent" formControlName="consumer">
                                </div>
                            </div> -->
                            <div class="form-group row">
                                <label class="col-sm-3 col-form-label">hide Credentials</label>
                                <div class="col-sm-9">
                                    <div class="media-body text-end icon-state switch-outline">
                                        <label class="switch">
                                            <input type="checkbox" checked="" formControlName="hideCredentials"><span
                                                class="switch-state bg-primary"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="form-group row">
                                <label class="col-sm-3 col-form-label">clock skew</label>
                                <div class="col-sm-9">
                                    <input type="number" class="form-control p-input" placeholder="clock skew"
                                        required id="email" name="email" formControlName="clockSkew">
                                </div>
                            </div> -->
                            <!-- <div class="form-group row">
                                <label class="col-sm-3 col-form-label">anonymous</label>
                                <div class="col-sm-9">
                                    <textarea class="form-control" formControlName="anonymous" id="anonymous">
                                    </textarea>
                                </div>
                            </div> -->
                            <div class="form-group row">
                                <label class="col-sm-3 col-form-label">validate request body</label>
                                <div class="col-sm-9">
                                    <div class="media-body text-end icon-state switch-outline">
                                        <label class="switch">
                                            <input type="checkbox" checked="" formControlName="validateRequestBody"><span
                                                class="switch-state bg-primary"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-3 col-form-label">enforce headers</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control p-input" placeholder="enforce headers"
                                        formControlName="enforceHeaders">
                                    <small>Gunakan koma sebagai pemisah jika mengisi lebih dari 1 enforce headers, field ini berisikan daftar dari header yang digunakan klien untuk pembuatan HTTP signature creation</small>
                                    <br>
                                    <span *ngIf="hmacAuthForm.controls.enforceHeaders.invalid && hmacAuthForm.controls.enforceHeaders.errors && (hmacAuthForm.controls.enforceHeaders.dirty || hmacAuthForm.controls.enforceHeaders.touched)">
                                        <small class="text-danger" *ngIf="hmacAuthForm.controls.enforceHeaders.hasError('pattern')"> Only letters, numbers, and special characters !@#$%^&*()_+-= are allowed.</small>
                                    </span>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-3 col-form-label">algorithms</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control p-input" placeholder="algorithms"
                                       formControlName="algorithms">
                                    <small>Gunakan koma sebagai pemisah jika mengisi lebih dari 1 algorithms, field ini berisikan nilai dari "hmac-sha1", "hmac-sha256", "hmac-sha384" dan "hmac-sha512"</small>
                                    <br>
                                    <span *ngIf="hmacAuthForm.controls.algorithms.invalid && hmacAuthForm.controls.algorithms.errors && (hmacAuthForm.controls.algorithms.dirty || hmacAuthForm.controls.algorithms.touched)">
                                        <small class="text-danger" *ngIf="hmacAuthForm.controls.algorithms.hasError('pattern')"> Only letters, numbers, and special characters !@#$%^&*()_+-= are allowed.</small>
                                    </span>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-3 col-form-label">enabled</label>
                                <div class="col-sm-9">
                                    <div class="media-body text-end icon-state switch-outline">
                                        <label class="switch">
                                            <input type="checkbox" checked="" formControlName="enabled"><span
                                                class="switch-state bg-primary"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div *ngIf="isCredentials" class="row">
                        <div class="col-md-12">
                            <div class="form-group row">
                                <label class="col-sm-3 col-form-label">username</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control p-input" placeholder="username" 
                                        id="username" name="username" formControlName="username"
                                        [ngClass]="{ 'is-valid' : hmacAuthForm.controls.username.valid , 'is-invalid' : hmacAuthForm.controls.username.invalid }">
                                    <span *ngIf="hmacAuthForm.controls.username.invalid && hmacAuthForm.controls.username.errors && (hmacAuthForm.controls.username.dirty || hmacAuthForm.controls.username.touched)">
                                        <small class="text-danger" *ngIf="hmacAuthForm.controls.username.hasError('pattern')"> Only letters, numbers, and special characters !@#$%^&*()_+-= are allowed.</small>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group row">
                                <label class="col-sm-3 col-form-label">secret</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control p-input" placeholder="secret"
                                        id="email" name="email" formControlName="secret">
                                        <span *ngIf="hmacAuthForm.controls.secret.invalid && hmacAuthForm.controls.secret.errors && (hmacAuthForm.controls.secret.dirty || hmacAuthForm.controls.secret.touched)">
                                            <small class="text-danger" *ngIf="hmacAuthForm.controls.secret.hasError('pattern')"> Only letters, numbers, and special characters !@#$%^&*()_+-= are allowed.</small>
                                        </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="submit" class="btn btn-success" [disabled]="isLoading|| hmacAuthForm.invalid">Simpan</button>&nbsp;
                    <button type="button" class="btn btn-danger" (click)="hideModal()">Keluar</button>
                </div>
            </div>
        </div>
    </form>
</div>

<!-- Hmac Auth End -->




<!-- JWT Modal -->
<div bsModal #jwtModal="bs-modal" class="modal fade" id="keyAuthModal" backdrop="static" keyboard="false" tabindex="-1"
    role="dialog" aria-labelledby="oauth2ModalLabel" aria-hidden="true">
    <form [formGroup]="jwtForm" (ngSubmit)="submitModal()">
        <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="modal-basic-title">{{tittle}} JWT</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="hideModal()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div *ngIf="!isCredentials" class="row">
                        <div class="col-md-12">
                            <!-- <div class="form-group row">
                                <label class="col-sm-3 col-form-label">consumer</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control p-input" placeholder="consumer" id="parent"
                                        name="parent" formControlName="consumer">
                                </div>
                            </div> -->
                            <!-- <div class="form-group row">
                                <label class="col-sm-3 col-form-label">uri param names</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control p-input" placeholder="uri param names"
                                        id="uriParamNames" name="uriParamNames" formControlName="uriParamNames">
                                    <small class="form-text text-muted" id="note">Gunakan koma tanpa spasi sebagai pemisah jika
                                        mengisi lebih dari satu value</small>
                                </div>
                            </div> -->
                            <!-- <div class="form-group row">
                                <label class="col-sm-3 col-form-label">cookie names</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control p-input" placeholder="cookie names"
                                        id="cookieNames" name="uriParamNames" formControlName="cookieNames">
                                    <small class="form-text text-muted" id="note">Gunakan koma tanpa spasi sebagai pemisah jika
                                        mengisi lebih dari satu value</small>
                                </div>
                            </div> -->
                            <div class="form-group row">
                                <label class="col-sm-3 col-form-label">key claim name</label>
                                <div class="col-sm-9">
                                    <!-- <input type="text" class="form-control p-input" placeholder="key claim name"
                                        id="keyClaimName" name="uriParamNames" formControlName="keyClaimName"> -->
                                    <textarea class="form-control" formControlName="keyClaimName" id="keyClaimName">
                                    </textarea>
                                    <span *ngIf="jwtForm.controls.keyClaimName.invalid && jwtForm.controls.keyClaimName.errors && (jwtForm.controls.keyClaimName.dirty || jwtForm.controls.keyClaimName.touched)">
                                        <small class="text-danger" *ngIf="jwtForm.controls.keyClaimName.hasError('pattern')"> Only letters, numbers, and special characters !@#$%^&*()_+-= are allowed.</small>
                                    </span>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-3 col-form-label">secret is base64</label>
                                <div class="col-sm-9">
                                    <div class="media-body text-end icon-state switch-outline">
                                        <label class="switch">
                                            <input type="checkbox" checked="" formControlName="secretIsBase64"><span
                                                class="switch-state bg-primary"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-3 col-form-label">claims to verify</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control p-input" placeholder="claims to verify"
                                        id="claimsToVerify" name="claimsToVerify" formControlName="claimsToVerify">
                                    <small class="form-text text-muted" id="note">Gunakan koma tanpa spasi sebagai pemisah jika
                                        mengisi lebih dari satu value, berisikan list dari klaim yg terdaftar dan juga dapat di verifikasi oleh kong yaitu 'exp,nbf'</small>
                                        <!-- <br> -->
                                        <span *ngIf="jwtForm.controls.claimsToVerify.invalid && jwtForm.controls.claimsToVerify.errors && (jwtForm.controls.claimsToVerify.dirty || jwtForm.controls.claimsToVerify.touched)">
                                            <small class="text-danger" *ngIf="jwtForm.controls.claimsToVerify.hasError('pattern')"> Only letters, numbers, and special characters !@#$%^&*()_+-= are allowed.</small>
                                        </span>
                                </div>
                            </div>
                            <!-- <div class="form-group row">
                                <label class="col-sm-3 col-form-label">anonymous</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control p-input" placeholder="anonymous"
                                        id="anonymous" name="anonymous" formControlName="anonymous">
                                </div>
                            </div> -->
                            <div class="form-group row">
                                <label class="col-sm-3 col-form-label">run on preflight</label>
                                <div class="col-sm-9">
                                    <div class="media-body text-end icon-state switch-outline">
                                        <label class="switch">
                                            <input type="checkbox" checked="" formControlName="runOnPreflight"><span
                                                class="switch-state bg-primary"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-3 col-form-label">maximum expiration</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control p-input" placeholder="maximum expiration"
                                        id="maximumExpiration" name="maximumExpiration"
                                        formControlName="maximumExpiration">
                                        <span *ngIf="jwtForm.controls.maximumExpiration.invalid && jwtForm.controls.maximumExpiration.errors && (jwtForm.controls.maximumExpiration.dirty || jwtForm.controls.maximumExpiration.touched)">
                                            <small class="text-danger" *ngIf="jwtForm.controls.maximumExpiration.hasError('pattern')"> Only numbers, the characters is not allowed.</small>    
                                        </span>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-3 col-form-label">enabled</label>
                                <div class="col-sm-9">
                                    <div class="media-body text-end icon-state switch-outline">
                                        <label class="switch">
                                            <input type="checkbox" checked="" formControlName="enabled"><span
                                                class="switch-state bg-primary"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="form-group row">
                                <label class="col-sm-3 col-form-label">header names</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control p-input" placeholder="header names"
                                        id="headerNames" name="headerNames" formControlName="headerNames">
                                    <small class="form-text text-muted" id="note">Gunakan koma tanpa spasi sebagai pemisah jika
                                        mengisi lebih dari satu value</small>
                                </div>
                            </div> -->
                        </div>
                    </div>
                    <div *ngIf="isCredentials" class="row">
                        <div class="col-md-12">
                            <div class="form-group row">
                                <label class="col-sm-3 col-form-label">secret</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control p-input" placeholder="secret" 
                                        id="email" name="email" formControlName="secret">
                                        <span *ngIf="jwtForm.controls.secret.invalid && jwtForm.controls.secret.errors && (jwtForm.controls.secret.dirty || jwtForm.controls.secret.touched)">
                                            <small class="text-danger" *ngIf="jwtForm.controls.secret.hasError('pattern')"> Only letters, numbers, and special characters !@#$%^&*()_+-= are allowed.</small>
                                        </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="submit" class="btn btn-success" [disabled]="isLoading||jwtForm.invalid">Simpan</button>&nbsp;
                    <button type="button" class="btn btn-danger" (click)="hideModal()">Keluar</button>
                </div>
            </div>
        </div>
    </form>
</div>
<!-- JWT Modal End -->