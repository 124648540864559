<ng-container  *ngIf="!isChildren" >
<div class="table-responsive">
<table class="table table-bordered table-striped table-sm">
    <tr class="itemRequest">
        <th>Response Name</th>
        <!-- <th>ISO Resp.</th> -->
        <th>Data Type</th>
        <th>Length</th>
        <th>Format</th>
        <th>Default Value</th>
        <th>Mandatory</th>
        <th>Active</th>
        <th *ngIf="!readonly">Action</th>
    </tr>
    <tr *ngIf="responseData.length < 1">
        <td colspan="9" class="text-center" > No Data
        </td>
    </tr>
    <ng-container  *ngFor="let data of responseData" >
        <tr>
            <td>
                {{data.name}}
            </td>
            <!-- <td>
                {{data.iso_response_text}}
            </td> -->
            <td>
                <ng-container  *ngIf="data.children?.length < 1">
                    {{data.dataType}}
                </ng-container>
            </td>
            <td>
                <ng-container  *ngIf="data.children?.length < 1">
                    {{data.length}}
                </ng-container>
            </td>            
            <td>
                <ng-container  *ngIf="data.children?.length < 1">
                    {{data.format}}
                </ng-container>
            </td>
            <td>
                <ng-container  *ngIf="data.children?.length < 1">
                    {{data.defaultValue}}
                </ng-container>
            </td>            
            <td>
                <ng-container  *ngIf="data.children?.length < 1">
                    <i *ngIf="!data.mandatory" class="fa fa-ban" style="color:red;"></i><i *ngIf="data.mandatory" class="fa fa-check" style="color:green;"></i>
                </ng-container>
            </td>
            <td>
                <ng-container  *ngIf="data.children?.length < 1">
                    <i *ngIf="!data.active" class="fa fa-ban" style="color:red;"></i><i *ngIf="data.active" class="fa fa-check" style="color:green;"></i>
                </ng-container>
            </td>
            <td *ngIf="!readonly">
                    <span class="linkAction pointer" *ngIf="data.dataType.includes('OBJECT')" (click)="onAdd(data)"><i class="fa fa-plus"></i> Child</span> &nbsp;
                    <span class="linkAction pointer" (click)="onEdit(data)"><i class="fa fa-pencil"></i> Edit</span> &nbsp;
                    <span class="linkAction pointer" (click)="onDelete(data)" *ngIf="data.children?.length < 1"><i class="fa fa-remove"></i> Delete</span> &nbsp;
                    
            </td>
        </tr>
        <app-response-body-view style="display: contents"  [responseData]="data.children" [readonly]="readonly" [isChildren]="true" [level]="level+1" ></app-response-body-view>
       
    </ng-container>        
</table>
</div>
</ng-container>
<ng-container  *ngIf="isChildren" >
    <ng-container  *ngFor="let data of responseData" >
        <tr >
            <td>
                <ng-container *ngFor="let n of [].constructor(level)">
                    <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
                </ng-container>
                {{data.name}}
            </td>
            <!-- <td>
                {{data.iso_response_text}}
            </td> -->
            <td>
                <ng-container  *ngIf="data.children?.length < 1">
                    {{data.dataType}}
                </ng-container>
            </td>
            <td>
                <ng-container  *ngIf="data.children?.length < 1">
                    {{data.length}}
                </ng-container>
            </td>            
            <td>
                <ng-container  *ngIf="data.children?.length < 1">
                    {{data.format}}
                </ng-container>
            </td>
            <td>
                <ng-container  *ngIf="data.children?.length < 1">
                    {{data.defaultValue}}
                </ng-container>
            </td>            
            <td>
                <ng-container  *ngIf="data.children?.length < 1">
                    <i *ngIf="!data.mandatory" class="fa fa-ban" style="color:red;"></i><i *ngIf="data.mandatory" class="fa fa-check" style="color:green;"></i>
                </ng-container>
            </td>
            <td>
                <ng-container  *ngIf="data.children?.length < 1">
                    <i *ngIf="!data.active" class="fa fa-ban" style="color:red;"></i><i *ngIf="data.active" class="fa fa-check" style="color:green;"></i>
                </ng-container>
            </td>
            <td *ngIf="!readonly">
                    <span class="linkAction pointer" *ngIf="data.dataType.includes('OBJECT')"  (click)="onAdd(data)"><i class="fa fa-plus"></i> Child</span> &nbsp;
                    <span class="linkAction pointer" (click)="onEdit(data)"><i class="fa fa-pencil"></i> Edit</span> &nbsp;
                    <span class="linkAction pointer" (click)="onDelete(data)" *ngIf="data.children?.length < 1"><i class="fa fa-remove"></i> Delete</span> &nbsp;
                    
            </td>
        </tr>
        <app-response-body-view  style="display: contents" [responseData]="data.children" [readonly]="readonly" [isChildren]="true" [level]="level+1" ></app-response-body-view>
        
    </ng-container>
</ng-container>
