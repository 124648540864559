import { Component,Input,Output,EventEmitter, ViewEncapsulation, ElementRef, HostListener, SimpleChanges, ChangeDetectorRef, ViewChild, TemplateRef  } from '@angular/core';


@Component({
  selector: 'app-response-body-view',
  templateUrl: './response-body-view.component.html',
  styleUrls: ['./response-body-view.component.scss']
})
export class ResponseBodyViewComponent {
  @ViewChild('childComponentTemplate') childComponentTemplate: TemplateRef<any>;
  @Input() responseData: any;
  @Input() readonly: boolean;
  @Input() isChildren: boolean = false;
  @Input() level: number = 0;
  @Input() dragStatus: boolean = false;
  
  @Output() clickAddChildButton= new EventEmitter();
  @Output() clickEditChildButton= new EventEmitter();
  @Output() clickDeleteChildButton= new EventEmitter();
  @Output() clickRulesChildButton= new EventEmitter();

  responseDataTransformed = [];
  responseDataTemp : [];
  showDropZone:boolean=false;

  @HostListener('onAdd', ['$event'])
  onAddEventCaptured(event: any) {
  }

  @HostListener('onEdit', ['$event'])
  onEditEventCaptured(event: any) {
  }

  @HostListener('onDelete', ['$event'])
      onDeleteEventCaptured(event: any) {
  }

  @HostListener('onRules', ['$event'])
  onRulesEventCaptured(event: any) {
  }

  ngOnChanges(changes: SimpleChanges) {        

  }

  constructor(private elementRef: ElementRef,private ref: ChangeDetectorRef) {


  }

  onAdd(data): void {
    const event: CustomEvent = new CustomEvent('onAdd', {
      bubbles: true,
      detail: data
    });
    this.elementRef.nativeElement.dispatchEvent(event);
  }

  onEdit(data): void {
    const event: CustomEvent = new CustomEvent('onEdit', {
      bubbles: true,
      detail: data
    });
    this.elementRef.nativeElement.dispatchEvent(event);
  }
  
  
  onDelete(data): void {
    const event: CustomEvent = new CustomEvent('onDelete', {
      bubbles: true,
      detail: data
    });
    this.elementRef.nativeElement.dispatchEvent(event);
  }
  
  onRules(data): void {
    const event: CustomEvent = new CustomEvent('onRules', {
      bubbles: true,
      detail: data
    });
    this.elementRef.nativeElement.dispatchEvent(event);
  }

  addButton(data){
    this.clickAddChildButton.emit(data);
  }

  editButton(data){
    this.clickEditChildButton.emit(data);
  }

  deleteButton(data){
    console.log("From deleteButton");
    this.clickDeleteChildButton.emit(data);
  }


  allowDrop(ev){
    ev.preventDefault();
  }

  dragStop(ev,data){
    this.showDropZone = false;
  }

  drag(ev,data) {
    ev.dataTransfer.setData("source_id", data.id);
    ev.dataTransfer.setData("source_parent", data.parent);
    this.showDropZone = true;
    //this.dragStatus = true;
    //console.log(data);
  }

  drop(ev,d) {
    let data = {
      "source_id":ev.dataTransfer.getData("source_id"),
      "source_parent":ev.dataTransfer.getData("source_parent"),
      "target_id":d.id,
      "target_parent":d.parent,
    }
    ev.preventDefault();
    console.log(data);
  }

  dropSibling(ev,d) {
    let data = {
      "source_id":ev.dataTransfer.getData("source_id"),
      "source_parent":ev.dataTransfer.getData("source_parent"),
      "target_id":d.id,
      "target_parent":d.parent,
    }
    ev.preventDefault();
    console.log(data);
  }

  dropChild(ev,d) {
    let data = {
      "source_id":ev.dataTransfer.getData("source_id"),
      "source_parent":ev.dataTransfer.getData("source_parent"),
      "target_id":d.id,
      "target_parent":d.parent,
    }
    ev.preventDefault();
    console.log(data);
  }

}
