import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { SharedService } from 'src/app/components/development/shared.service';
import { ServerService } from 'src/app/service/server.service';
import { environment } from 'src/environments/environment';
declare var require
const Swal = require('sweetalert2')

@Component({
  selector: 'app-logging',
  templateUrl: './logging.component.html',
  styleUrls: ['./logging.component.scss']
})
export class LoggingComponent implements OnInit {
  @Output("showModalPlugin") showModalPlugin: EventEmitter<any> = new EventEmitter();

  @Input() imageSize: any
  @Input() isModal: boolean
  @Input() envi: string
  @Input() id: number;
  @Input() fromMenu: string
  key: String
  isLoading: boolean

  httpLogSave = environment.path.base + environment.path.api_management.base 



  public httpLogForm: FormGroup

  @ViewChild('httpLogModal') httpLogModal: ModalDirective

  constructor(private fb: FormBuilder,
    private server: ServerService,
    private shared: SharedService) {
    this.httpLogForm = fb.group({
      consumer: null,
      httpEndpoint: null,
      method: null,
      contentType: null,
      timeout: null,
      keepalive: null,
      retryCount: null,
      queueSize: null,
      flushTimeout: null,
      headers: null,
      customFieldsByLua: null,
      enabled: false
    })

    this.shared.isLoading().subscribe((isLoading) => {
      this.isLoading = isLoading;
    })
  }

  ngOnInit(): void {
  }

  showAddModal(key: string) {
    this.key = key
    switch (key) {
      case "httpLog":
        this.httpLogForm.reset()
        this.httpLogModal.show()
        break
      default:
        console.log("No such key exist!")
        break
    }
  }

  submit() {
    switch (this.key) {
      case "httpLog":
        this.submitHttpLog()
        break
      default:
        console.log("No such key exist!")
        break
    }
  }

  submitHttpLog() {
    let data = this.httpLogForm.value
    let jsonData = {
      "enabled": data.enabled,
      "httpEndpoint": data.httpEndpoint,
      "method": data.method,
      "contentType": data.contentType,
      "timeout": data.timeout,
      "keepalive": data.keepalive,
      "flushTimeout": data.flushTimeout,
      "retryCount": data.retryCount,
      "queueSize": data.queueSize
    }
    let url = this.fromMenu == "services" && this.id ? this.httpLogSave + '/services/' + this.id : this.httpLogSave
    this.server.doPostWithEnvi(jsonData, url, this.envi).subscribe(async resp => {
      if (resp) {
        Swal.fire('Success!', resp['message'], 'success').then((result) => {
          if (result.isConfirmed) {
            this.close()
          }
        });
      } else {
        Swal.fire('Failure!', resp['message'], 'error')
      }
    })
  }

  close() {
    this.showModalPlugin.emit()
    this.httpLogModal.hide()
  }

}
