
import { Component,Input,Output,EventEmitter, ViewEncapsulation, ElementRef, HostListener, SimpleChanges, ChangeDetectorRef, ViewChild, TemplateRef  } from '@angular/core';

@Component({
  selector: 'app-request-header-view',
  templateUrl: './request-header-view.component.html',
  styleUrls: ['./request-header-view.component.scss']
})
export class RequestHeaderViewComponent {
  @Input() headerData: any;
  @Input() readonly: boolean;
  
  @Output() clickAddChildButton= new EventEmitter();
  @Output() clickEditChildButton= new EventEmitter();
  @Output() clickDeleteChildButton= new EventEmitter();
  
  @HostListener('onAdd', ['$event'])
  onAddEventCaptured(event: any) {
  }

  @HostListener('onEdit', ['$event'])
  onEditEventCaptured(event: any) {
  }

  @HostListener('onDelete', ['$event'])
      onDeleteEventCaptured(event: any) {
  }

  constructor(private elementRef: ElementRef,private ref: ChangeDetectorRef) {
    
  }

  onAdd(data): void {
    const event: CustomEvent = new CustomEvent('onAdd', {
      bubbles: true,
      detail: data
    });
    this.elementRef.nativeElement.dispatchEvent(event);
  }

  onEdit(data): void {
    const event: CustomEvent = new CustomEvent('onEdit', {
      bubbles: true,
      detail: data
    });
    this.elementRef.nativeElement.dispatchEvent(event);
  }
  
  
  onDelete(data): void {
    const event: CustomEvent = new CustomEvent('onDelete', {
      bubbles: true,
      detail: data
    });
    this.elementRef.nativeElement.dispatchEvent(event);
  }


}


