import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServerService } from 'src/app/service/server.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {

  constructor(public router:Router,
    private serverService: ServerService) { }
  userName:String
  email:String

  ngOnInit() {
    if(localStorage.getItem('userInfo')){
      let user = JSON.parse(localStorage.getItem('userInfo'))
      this.userName = user.fullname;
      this.email = user.email
    }

  }

  logOut(){
    if(localStorage.getItem('userInfo')){
      let user = JSON.parse(localStorage.getItem('userInfo'))
      this.serverService.doPost("",environment.path.base+environment.path.bank.base+'/logout/'+user.id).subscribe(async resp => {
        if(resp){
          console.log("Clear session DONE.")
        }
      })
    }
    sessionStorage.clear();
    localStorage.clear()
    this.router.navigate(['auth/login']);
  }

}
