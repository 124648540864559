import { Routes } from '@angular/router';


export const content: Routes = [
  {
    path: 'sample-page',
    loadChildren: () => import('../../components/sample/sample.module').then(m => m.SampleModule)
  },
  {
    path: 'development',
    loadChildren: () => import('../../components/development/development.module').then(m => m.DevelopmentModule)
  },
  {
    path: 'consumer',
    loadChildren: () => import('../../components/consumer/consumer.module').then(m => m.ConsumerModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('../../components/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'account',
    loadChildren: () => import('../../components/account/account.module').then(m => m.AccountModule)
  },
  {
    path: 'bank',
    loadChildren: () => import('../../components/bank/bank.module').then(m => m.BankModule)
  }
];
