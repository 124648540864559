import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { SharedService } from 'src/app/components/development/shared.service';
import { ServerService } from 'src/app/service/server.service';
import { environment } from 'src/environments/environment';


declare var require
const Swal = require('sweetalert2')

@Component({
  selector: 'app-other-plugins',
  templateUrl: './other-plugins.component.html',
  styleUrls: ['./other-plugins.component.scss']
})
export class OtherPluginsComponent implements OnInit {
  @ViewChild('sigmaRespTransModal') sigmaRespTransModal: ModalDirective;
  @ViewChild('sigmaHttpLogModal') sigmaHttpLogModal: ModalDirective;sigmaHmacAuth
  @ViewChild('sigmaHmacAuth') sigmaHmacAuthModal: ModalDirective;

  sigmaRespTransformSaveUrl = environment.path.base + environment.path.api_management.base + environment.path.api_management.telkomsigma_response_transformer
  sigmaHttpLogSaveUrl = environment.path.base + environment.path.api_management.base + environment.path.api_management.sigmaHttpLog
  sigmaHmacAuthSaveUrl = environment.path.base + environment.path.api_management.base + environment.path.api_management.sigmaHmacAuth




  @Output("showModalPlugin") showModalPlugin: EventEmitter<any> = new EventEmitter();
  @Output("reloadDatatables") reloadDatatables: EventEmitter<any> = new EventEmitter();
  @Input() isModal: boolean;
  @Input() envi: string;
  @Input() id: number;
  @Input() fromMenu: string;
  tittle:string
  isLoading: boolean


  public sigmaRespTransForm: FormGroup;
  public sigmaHttpLogForm: FormGroup;
  public sigmaHmacAuthForm: FormGroup;
  key: String
  isEdit:Boolean
  pluginId:String

  constructor(private fb: FormBuilder,
    private server: ServerService,
    private shared: SharedService
    ) { 
    this.sigmaRespTransForm = fb.group({
      serviceCode: [null, [Validators.pattern('[0-9]*'), Validators.maxLength(3)]],
      showDebug: [false],
      enabled: [false],
    });

    this.sigmaHttpLogForm = fb.group({
      consumer: null,
      httpEndpoint: [null,[Validators.required,Validators.pattern(/^[a-zA-Z0-9!@#\$%\^\&*\)\(+=:/._ -]+$/)]],
      method: null,
      contentType: [null,[Validators.pattern(/^[a-zA-Z0-9!@#\$%\^\&*\)\(+=._ :'"{},\[\]\-\s\S]+$/)]],
      timeout: [null,[Validators.pattern(/^[0-9]+$/)]],
      keepalive:[null,[Validators.pattern(/^[0-9]+$/)]],
      retryCount:[null,[Validators.pattern(/^[0-9]+$/)]],
      queueSize:[null,[Validators.pattern(/^[0-9]+$/)]],
      flushTimeout:[null,[Validators.pattern(/^[0-9]+$/)]],
      enabled: false
    })

    this.sigmaHmacAuthForm = fb.group({
      hideCredentials: [false],
      headerName: [null,[Validators.required]],
      stringToSign: [null,[Validators.required]],
      parameters: [null,[Validators.required]],
      algorithms: [null,[Validators.required]],
      secretKey: [null,[Validators.required,Validators.pattern(/^[a-zA-Z0-9!@#\$%\^\&*\)\(+=:/._ -]+$/)]],
      enabled:[false]
    })

    this.shared.isLoading().subscribe((isLoading) => {
      this.isLoading = isLoading;
    })
  }

  ngOnInit(): void {
  }


  fetchDataModal(key, data){
    this.id= data.service ? data.service.id : data.route ? data.route.id : data.consumer ? data.consumer.id : null
    this.pluginId= data.id
    switch (key) {
      case 'sigmaRespTransform':
        this.sigmaRespTransForm.controls.serviceCode.setValue(data.config.serviceCode)
        this.sigmaRespTransForm.controls.showDebug.setValue(data.config.showDebug)
        this.sigmaRespTransForm.controls.enabled.setValue(data.enabled)
        break;
      case 'sigmaHttpLog':
        this.sigmaHttpLogForm.controls.consumer.setValue(data.config.consumer)
        this.sigmaHttpLogForm.controls.httpEndpoint.setValue(data.config.httpEndpoint)
        this.sigmaHttpLogForm.controls.method.setValue(data.config.method)
        this.sigmaHttpLogForm.controls.contentType.setValue(data.config.contentType)
        this.sigmaHttpLogForm.controls.timeout.setValue(data.config.timeout)
        this.sigmaHttpLogForm.controls.keepalive.setValue(data.config.keepalive)
        this.sigmaHttpLogForm.controls.retryCount.setValue(data.config.retryCount)
        this.sigmaHttpLogForm.controls.queueSize.setValue(data.config.queueSize)
        this.sigmaHttpLogForm.controls.flushTimeout.setValue(data.config.flushTimeout)
        this.sigmaHttpLogForm.controls.enabled.setValue(data.enabled)
        break;
      case 'sigmaHmacAuth':
        this.sigmaHmacAuthForm.controls.hideCredentials.setValue(data.config.hideCredentials)
        this.sigmaHmacAuthForm.controls.headerName.setValue(data.config.headerName)
        this.sigmaHmacAuthForm.controls.stringToSign.setValue(data.config.stringToSign)
        this.sigmaHmacAuthForm.controls.parameters.setValue(data.config.parameters)
        this.sigmaHmacAuthForm.controls.algorithms.setValue(data.config.algorithms)
        this.sigmaHmacAuthForm.controls.secretKey.setValue(data.config.secretKey)
        this.sigmaHmacAuthForm.controls.enabled.setValue(data.enabled)
          break;
      default:
        console.log("No key Choosen!!!")
        break;
    }
  }


  showModalAndSetForm(key){
    switch (key) {
      case 'sigmaRespTransform':
        this.sigmaRespTransModal.show()
        break;
      case 'sigmaHttpLog':
        this.sigmaHttpLogModal.show()
        break;
      case 'sigmaHmacAuth':
        if(this.sigmaHmacAuthForm.controls.headerName.value==null){
          this.sigmaHmacAuthForm.controls.headerName.setValue("SIGNATURE")
        }
        this.sigmaHmacAuthModal.show()
        break;
      default:
        console.log("No key Choosen!!!")
        break;
    }
  }


  showAddModal(key, data) {
    this.key = key
    if(data!=null){
      this.isEdit=true
      this.tittle='UBAH'
      this.fetchDataModal(key,data)
    }else{
      this.tittle='TAMBAH'
    }
    this.showModalAndSetForm(key)
  }


  submitModal(){
    switch (this.key) {
      case 'sigmaRespTransform':
        this.submitRespTransform()
        break;
      case 'sigmaHttpLog':
        this.submitSigmaHttpLog()
        break;
      case 'sigmaHmacAuth':
       this.submitSigmaHmacAuth()
        break;
      default:
        console.log("No key Choosen!!!");
        break;
    }

  }

  hideModal(){
    if(!this.isEdit){
      this.showModalPlugin.emit()
    }else{
      this.reloadDatatables.emit()
    }
    this.isEdit=false
    switch (this.key) {
      case 'sigmaRespTransform':
        this.sigmaRespTransForm.reset()
        this.sigmaRespTransModal.hide()
        break;
      case 'sigmaHttpLog':
        this.sigmaHttpLogForm.reset()
        this.sigmaHttpLogModal.hide()
        break;
      case 'sigmaHmacAuth':
        this.sigmaHmacAuthForm.reset()
        this.sigmaHmacAuthModal.hide()
        break;
      default:
        console.log("No key Choosen!!!")
        break;
    }
  }


  addRespTransform(jsonData){
    let url = this.fromMenu=="services" && this.id ? this.sigmaRespTransformSaveUrl+'/service/'+this.id : this.fromMenu=="routes" && this.id?this.sigmaRespTransformSaveUrl+'/route/'+this.id:this.sigmaRespTransformSaveUrl
    this.server.doPostWithEnvi(jsonData, url, this.envi).subscribe(async resp => {
      if (resp) {
        Swal.fire('Success!', resp['message'], 'success').then((result) => {
          if (result.isConfirmed) {
            this.hideModal()
          }
        });
      } else {
        Swal.fire('Failure!', resp['message'], 'error')
      }
    })
  }

  editRespTransform(jsonData){
    let url = this.fromMenu=="services" && this.id ? this.sigmaRespTransformSaveUrl+'/'+this.pluginId+'/service/'+this.id : this.fromMenu=="routes" && this.id?this.sigmaRespTransformSaveUrl+'/'+this.pluginId+'/route/'+this.id:this.sigmaRespTransformSaveUrl+'/'+this.pluginId
    this.server.doPutWithEnvi(jsonData, url, this.envi).subscribe(async resp => {
      if (resp) {
        Swal.fire('Success!', resp['message'], 'success').then((result) => {
          if (result.isConfirmed) {
            this.hideModal()
          }
        });
      } else {
        Swal.fire('Failure!', resp['message'], 'error')
      }
    })
  }


    submitRespTransform(){
    let data = this.sigmaRespTransForm.value
    let jsonData = {
    	enabled: data.enabled,
	    serviceCode: data.serviceCode,
      showDebug: data.showDebug
    }
    if(!this.isEdit){
      this.addRespTransform(jsonData)
    }else{
      this.editRespTransform(jsonData)
    }

  }

  addSigmaHttpLog(jsonData){
    let url = this.fromMenu=="services" && this.id ? this.sigmaHttpLogSaveUrl+'/service/'+this.id : this.fromMenu=="routes" && this.id?this.sigmaHttpLogSaveUrl+'/route/'+this.id: this.fromMenu=="consumers" && this.id?this.sigmaHttpLogSaveUrl+environment.path.api_management.consumers+'/'+this.id :this.sigmaHttpLogSaveUrl
    this.server.doPostWithEnvi(jsonData, url, this.envi).subscribe(async resp => {
      if (resp) {
        Swal.fire('Success!', resp['message'], 'success').then((result) => {
          if (result.isConfirmed) {
            this.hideModal()
          }
        });
      } else {
        Swal.fire('Failure!', resp['message'], 'error')
      }
    })
  }

  editSigmaHttpLog(jsonData){
    let url = this.fromMenu=="services" && this.id ? this.sigmaHttpLogSaveUrl+'/'+this.pluginId+'/service/'+this.id : this.fromMenu=="routes" && this.id?this.sigmaHttpLogSaveUrl+'/'+this.pluginId+'/route/'+this.id: this.fromMenu=="consumers" && this.id?this.sigmaHttpLogSaveUrl+'/'+this.pluginId+environment.path.api_management.consumers+'/'+this.id :this.sigmaHttpLogSaveUrl+'/'+this.pluginId
    this.server.doPutWithEnvi(jsonData, url, this.envi).subscribe(async resp => {
      if (resp) {
        Swal.fire('Success!', resp['message'], 'success').then((result) => {
          if (result.isConfirmed) {
            this.hideModal()
          }
        });
      } else {
        Swal.fire('Failure!', resp['message'], 'error')
      }
    })
  }

  submitSigmaHttpLog() {
    let data = this.sigmaHttpLogForm.value
    let jsonData = {
      "enabled": data.enabled,
      "httpEndpoint": data.httpEndpoint,
      "method": data.method,
      "contentType": data.contentType,
      "timeout": data.timeout,
      "keepalive": data.keepalive,
      "flushTimeout": data.flushTimeout,
      "retryCount": data.retryCount,
      "queueSize": data.queueSize
    }

    if(!this.isEdit){
      this.addSigmaHttpLog(jsonData)
    }else{
      this.editSigmaHttpLog(jsonData)
    }
    
  }

  addSigmaHmacAuth(jsonData){
    let url = this.fromMenu=="services" && this.id ? this.sigmaHmacAuthSaveUrl+'/service/'+this.id : this.fromMenu=="routes" && this.id?this.sigmaHmacAuthSaveUrl+'/route/'+this.id: this.fromMenu=="consumers" && this.id?this.sigmaHmacAuthSaveUrl+environment.path.api_management.consumers+'/'+this.id :this.sigmaHmacAuthSaveUrl
    this.server.doPostWithEnvi(jsonData, url, this.envi).subscribe(async resp => {
      if (resp) {
        Swal.fire('Success!', resp['message'], 'success').then((result) => {
          if (result.isConfirmed) {
            this.hideModal()
          }
        });
      } else {
        Swal.fire('Failure!', resp['message'], 'error')
      }
    })
  }

  editSigmaHmacAuth(jsonData){
    let url = this.fromMenu=="services" && this.id ? this.sigmaHmacAuthSaveUrl+'/'+this.pluginId+'/service/'+this.id : this.fromMenu=="routes" && this.id?this.sigmaHmacAuthSaveUrl+'/'+this.pluginId+'/route/'+this.id: this.fromMenu=="consumers" && this.id?this.sigmaHmacAuthSaveUrl+'/'+this.pluginId+environment.path.api_management.consumers+'/'+this.id :this.sigmaHmacAuthSaveUrl+'/'+this.pluginId
    this.server.doPutWithEnvi(jsonData, url, this.envi).subscribe(async resp => {
      if (resp) {
        Swal.fire('Success!', resp['message'], 'success').then((result) => {
          if (result.isConfirmed) {
            this.hideModal()
          }
        });
      } else {
        Swal.fire('Failure!', resp['message'], 'error')
      }
    })
  }

  submitSigmaHmacAuth() {
    let data = this.sigmaHmacAuthForm.value
    let jsonData = {
      "hideCredentials":data.hideCredentials,
      "headerName":data.headerName,
      "stringToSign":data.stringToSign,
      "parameters":data.parameters,
      "algorithms":data.algorithms,
      "secretKey":data.secretKey,
      "enabled": data.enabled,
    }

    if(!this.isEdit){
      this.addSigmaHmacAuth(jsonData)
    }else{
      this.editSigmaHmacAuth(jsonData)
    }
    
  }

}
