<!-- ACL Modal -->
<div bsModal #aclModal="bs-modal" class="modal fade" id="addPath" backdrop="static" keyboard="false" tabindex="-1"
    role="dialog" aria-labelledby="addModalLabel" aria-hidden="true">
    <form [formGroup]="aclForm">
        <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header ">
                    <h4 class="modal-title " id="addModalLabel">{{tittle}} ACL</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <hr>
                <div class="modal-body">
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">allow</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control p-input" placeholder="allow" id="allow" name="allow"
                                value="" formControlName="allow">
                                <small class="form-text text-muted" id="note">Jika mengisi lebih
                                    dari satu allow, pisahkan tag dengan koma tanpa spasi</small>
                                    <span *ngIf="aclForm.controls.allow.invalid && aclForm.controls.allow.errors && (aclForm.controls.allow.dirty || aclForm.controls.allow.touched)">
                                        <small class="text-danger" *ngIf="aclForm.controls.allow.hasError('pattern')"> Only letters, numbers, and special characters !@#$%^&*()_+-= are allowed.</small>
                                    </span>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">deny</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control p-input" placeholder="deny" id="deny" name="deny"
                                value="" formControlName="deny">
                                <small class="form-text text-muted" id="note">Jika mengisi lebih
                                    dari satu deny, pisahkan tag dengan koma tanpa spasi</small>
                                    <span *ngIf="aclForm.controls.deny.invalid && aclForm.controls.deny.errors && (aclForm.controls.deny.dirty || aclForm.controls.deny.touched)">
                                        <small class="text-danger" *ngIf="aclForm.controls.deny.hasError('pattern')"> Only letters, numbers, and special characters !@#$%^&*()_+-= are allowed.</small>
                                    </span>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">hide Groups Headers</label>
                        <div class="col-sm-9 media-body text-end icon-state switch-outline">
                            <label class="switch">
                                <input type="checkbox" checked="" formControlName="hideGroupHeaders"><span
                                    class="switch-state bg-primary"></span>
                            </label>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">enabled</label>
                        <div class="col-sm-9 media-body text-end icon-state switch-outline">
                            <label class="switch">
                                <input type="checkbox" checked="" formControlName="enabled"><span
                                    class="switch-state bg-primary"></span>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-sm btn-success " [disabled]="isLoading||aclForm.invalid" (click)="submit()"
                        [disabled]="aclForm.invalid||isLoading">Simpan</button>
                    <button type="button" class="btn btn-sm btn-danger " (click)="close()">Kembali</button>
                </div>
            </div>
        </div>
    </form>
</div>
<!-- End ACL Modal -->
<!-- Cors Modal -->
<div bsModal #corsModal="bs-modal" class="modal fade" id="addPath" backdrop="static" keyboard="false" tabindex="-1"
    role="dialog" aria-labelledby="addModalLabel" aria-hidden="true">
    <form [formGroup]="corsForm">
        <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header ">
                    <h4 class="modal-title " id="addModalLabel">{{tittle}} CORS</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">origins</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control p-input" placeholder="origins" id="origins"
                                name="origins" value="" formControlName="origins">
                            <small class="form-text text-muted" id="note">Jika mengisi lebih
                                dari satu origin, pisahkan tag dengan koma tanpa spasi</small>
                                <span *ngIf="corsForm.controls.origins.invalid && corsForm.controls.origins.errors && (corsForm.controls.origins.dirty || corsForm.controls.origins.touched)">
                                    <small class="text-danger" *ngIf="corsForm.controls.origins.hasError('pattern')"> Only letters, numbers, and special characters !@#$%^&*()_+-= are allowed.</small>
                                </span>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">headers</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control p-input" placeholder="headers" id="headers"
                                name="headers" value="" formControlName="headers">
                            <small class="form-text text-muted" id="note">Jika mengisi lebih
                                dari satu header, pisahkan tag dengan koma tanpa spasi</small>
                                <span *ngIf="corsForm.controls.headers.invalid && corsForm.controls.headers.errors && (corsForm.controls.headers.dirty || corsForm.controls.headers.touched)">
                                    <small class="text-danger" *ngIf="corsForm.controls.headers.hasError('pattern')"> Only letters, numbers, and special characters !@#$%^&*()_+-= are allowed.</small>
                                </span>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">exposed headers</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control p-input" placeholder="exposed headers"
                                id="exposedHeaders" name="exposedHeaders" value="" formControlName="exposedHeaders">
                            <small class="form-text text-muted" id="note">Jika mengisi lebih
                                dari satu header, pisahkan tag dengan koma tanpa spasi</small>
                                <span *ngIf="corsForm.controls.exposedHeaders.invalid && corsForm.controls.exposedHeaders.errors && (corsForm.controls.exposedHeaders.dirty || corsForm.controls.exposedHeaders.touched)">
                                    <small class="text-danger" *ngIf="corsForm.controls.exposedHeaders.hasError('pattern')"> Only letters, numbers, and special characters !@#$%^&*()_+-= are allowed.</small>
                                </span>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">methods</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control p-input" placeholder="methods" id="methods"
                                name="methods" value="" formControlName="methods">
                            <small class="form-text text-muted" id="note">Jika mengisi lebih
                                dari satu method, pisahkan tag dengan koma tanpa spasi</small>
                                <span *ngIf="corsForm.controls.methods.invalid && corsForm.controls.methods.errors && (corsForm.controls.methods.dirty || corsForm.controls.methods.touched)">
                                    <small class="text-danger" *ngIf="corsForm.controls.methods.hasError('pattern')"> Only letters, numbers, and special characters !@#$%^&*()_+-= are allowed.</small>
                                </span>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">max age</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control p-input" placeholder="max age" id="maxAge"
                                name="maxAge" value="" formControlName="maxAge">
                                <span *ngIf="corsForm.controls.maxAge.invalid && corsForm.controls.maxAge.errors && (corsForm.controls.maxAge.dirty || corsForm.controls.maxAge.touched)">
                                    <small class="text-danger" *ngIf="corsForm.controls.maxAge.hasError('pattern')"> Only numbers, the character is not allowed.</small>
                                    <br *ngIf="corsForm.controls.maxAge.hasError('pattern') && corsForm.controls.maxAge.hasError('min')">                            
                                    <small class="text-danger" *ngIf="corsForm.controls.maxAge.hasError('min')">max age harus lebih besar dari 0.</small>  
                                </span>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">credintials</label>
                        <div class="col-sm-9 media-body text-end icon-state switch-outline">
                            <label class="switch">
                                <input type="checkbox" checked="" formControlName="credentials"><span
                                    class="switch-state bg-primary"></span>
                            </label>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">preflight continue</label>
                        <div class="col-sm-9 media-body text-end icon-state switch-outline">
                            <label class="switch">
                                <input type="checkbox" checked="" formControlName="preflightContinue"><span
                                    class="switch-state bg-primary"></span>
                            </label>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">enabled</label>
                        <div class="col-sm-9 media-body text-end icon-state switch-outline">
                            <label class="switch">
                                <input type="checkbox" checked="" formControlName="enabled"><span
                                    class="switch-state bg-primary"></span>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-sm btn-success " [disabled]="isLoading|| corsForm.invalid" (click)="submit()"
                        [disabled]="corsForm.invalid||isLoading">Simpan</button>
                    <button type="button" class="btn btn-sm btn-danger " (click)="close()">Kembali</button>
                </div>
            </div>
        </div>
    </form>
</div>
<!-- End Cors Modal -->
<!-- IP Restriction Modal -->
<div bsModal #ipRestModal="bs-modal" class="modal fade" id="addPath" backdrop="static" keyboard="false" tabindex="-1"
    role="dialog" aria-labelledby="addModalLabel" aria-hidden="true">
    <form [formGroup]="ipRestForm">
        <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header ">
                    <h4 class="modal-title " id="addModalLabel">{{tittle}} IP Restriction</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">allow</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control p-input" placeholder="allow" id="allow" name="allow"
                                value="" formControlName="allow" (blur)="onBlur($event,'allow')"
                                [ngClass]="{ 'is-valid' : ipRestForm.controls.allow.valid , 'is-invalid' : ipRestForm.controls.allow.invalid }">
                            <small class="form-text text-muted" id="note">Jika mengisi lebih
                                dari satu origin, pisahkan tag dengan koma tanpa spasi</small>
                            <span *ngIf="ipRestForm.controls.allow.invalid && ipRestForm.controls.allow.errors && (ipRestForm.controls.allow.dirty || ipRestForm.controls.allow.touched)">
                                <small class="text-danger" *ngIf="ipRestForm.controls.allow.hasError('pattern')">Hanya diperbolehkan numerik dan titik(.).</small>
                            </span>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">deny</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control p-input" placeholder="deny" id="deny" name="deny"
                                value="" formControlName="deny" (blur)="onBlur($event,'deny')"
                                [ngClass]="{ 'is-valid' : ipRestForm.controls.deny.valid , 'is-invalid' : ipRestForm.controls.deny.invalid }">
                            <small class="form-text text-muted" id="note">Jika mengisi lebih
                                dari satu origin, pisahkan tag dengan koma tanpa spasi</small>
                            <span *ngIf="ipRestForm.controls.deny.invalid && ipRestForm.controls.deny.errors && (ipRestForm.controls.deny.dirty || ipRestForm.controls.deny.touched)">
                                <small class="text-danger" *ngIf="ipRestForm.controls.deny.hasError('pattern')">Hanya diperbolehkan numerik dan titik(.).</small>
                            </span>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">status</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control p-input" placeholder="status" id="status"
                                name="status" value="" formControlName="status"
                                [ngClass]="{ 'is-valid' : ipRestForm.controls.status.valid , 'is-invalid' : ipRestForm.controls.status.invalid }">
                                <span *ngIf="ipRestForm.controls.status.invalid && ipRestForm.controls.status.errors && (ipRestForm.controls.status.dirty || ipRestForm.controls.status.touched)">
                                    <small class="text-danger" *ngIf="ipRestForm.controls.status.hasError('pattern')">Hanya diperbolehkan numerik</small>
                                </span>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">message</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control p-input" placeholder="message" id="message"
                                name="message" value="" formControlName="message">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">enabled</label>
                        <div class="col-sm-9 media-body text-end icon-state switch-outline">
                            <label class="switch">
                                <input type="checkbox" checked="" formControlName="enabled"><span
                                    class="switch-state bg-primary"></span>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-sm btn-success " (click)="submit()"
                        [disabled]="ipRestForm.invalid||isLoading">Simpan</button>
                    <button type="button" class="btn btn-sm btn-danger " (click)="close()">Kembali</button>
                </div>
            </div>
        </div>
    </form>
</div>
<!-- End IP Restriction Modal -->
<!-- Bot Detection Modal -->
<div bsModal #botDetectionModal="bs-modal" class="modal fade" id="addPath" backdrop="static" keyboard="false"
    tabindex="-1" role="dialog" aria-labelledby="addModalLabel" aria-hidden="true">
    <form [formGroup]="botDetectionForm">
        <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header ">
                    <h4 class="modal-title " id="addModalLabel">{{tittle}} Bot Detection</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">consumer</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control p-input" placeholder="consumer" id="consumer"
                                name="consumer" value="" formControlName="consumer">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">allow</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control p-input" placeholder="allow" id="allow" name="allow"
                                value="" formControlName="allow">
                            <small class="form-text text-muted" id="note">Jika mengisi lebih
                                dari satu origin, pisahkan tag dengan koma tanpa spasi</small>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">deny</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control p-input" placeholder="deny" id="deny" name="deny"
                                value="" formControlName="deny">
                            <small class="form-text text-muted" id="note">Jika mengisi lebih
                                dari satu origin, pisahkan tag dengan koma tanpa spasi</small>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-sm btn-success " (click)="submit()"
                        [disabled]="botDetectionForm.invalid||isLoading">Simpan</button>
                    <button type="button" class="btn btn-sm btn-danger " (click)="close()">Kembali</button>
                </div>
            </div>
        </div>
    </form>
</div>
<!-- End Bot Detection Modal -->
<!-- ACME Modal -->
<!-- Belum ada jiranya -->
<!-- End ACME Modal -->