
 <div bsModal #rateLimitingModal="bs-modal" class="modal fade" id="addPath" backdrop="static" keyboard="false" tabindex="-1"
    role="dialog" aria-labelledby="addModalLabel" aria-hidden="true">
    <form [formGroup]="rateLimitingForm">
        <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title " id="addModalLabel">Tambah Rate Limiting</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">second</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control p-input" placeholder="second" id="second"
                                value="" formControlName="second">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">minute</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control p-input" placeholder="minute" id="minute"
                                value="" formControlName="minute">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">hour</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control p-input" placeholder="hour" id="hour"
                                value="" formControlName="hour">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">day</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control p-input" placeholder="day" id="day"
                                value="" formControlName="day">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">month</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control p-input" placeholder="month" id="month"
                                value="" formControlName="month">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">year</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control p-input" placeholder="year" id="year"
                                value="" formControlName="year">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">limit by</label>
                        <div class="col-sm-9">
                            <select id="approved" class="form-control form-select p-input" placeholder="Select type" formControlName="limitBy">
                                <option value="consumer">consumer</option>
                                <option value="credential">credential</option>
                                <option value="ip">ip</option>
                                <option value="service">service</option>
                                <option value="header">header</option>
                                <option value="path">path</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">header name</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control p-input" placeholder="header name" id="headerName"
                                value="" formControlName="headerName">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">path</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control p-input" placeholder="path" id="path"
                                value="" formControlName="path">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">policy</label>
                        <div class="col-sm-9">
                            <select id="approved" class="form-control form-select p-input" placeholder="Select type" formControlName="policy">
                                <option value="local">local</option>
                                <option value="cluster">cluster</option>
                                <option value="redis">redis</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">fault tolerant</label>
                        <div class="col-sm-9">
                            <div class="media-body text-end icon-state switch-outline">
                                <label class="switch">
                                    <input type="checkbox" checked="" formControlName="faultTolerant"><span
                                        class="switch-state bg-primary"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">redis host</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control p-input" placeholder="redis host" id="redisHost"
                                value="" formControlName="redisHost">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">redis port</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control p-input" placeholder="redis port" id="redisPort"
                                value="" formControlName="redisPort">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">redis password</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control p-input" placeholder="redis password" id="redisPassword"
                                value="" formControlName="redisPassword">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">redis username</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control p-input" placeholder="redis username" id="redisUsername"
                                value="" formControlName="redisUsername">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">redis ssl</label>
                        <div class="col-sm-9">
                            <div class="media-body text-end icon-state switch-outline">
                                <label class="switch">
                                    <input type="checkbox" checked="" formControlName="redisSsl"><span
                                        class="switch-state bg-primary"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">redis ssl verify</label>
                        <div class="col-sm-9">
                            <div class="media-body text-end icon-state switch-outline">
                                <label class="switch">
                                    <input type="checkbox" checked="" formControlName="redisSslVerify"><span
                                        class="switch-state bg-primary"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">redis server name</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control p-input" placeholder="redis server name" id="redisServerName"
                            value="" formControlName="redisServerName">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">redis time out</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control p-input" placeholder="redis time out" id="redisTimeout"
                            value="" formControlName="redisTimeout">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">redis database</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control p-input" placeholder="redis database" id="redisDatabase"
                            value="" formControlName="redisDatabase">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">hide client headers</label>
                        <div class="col-sm-9">
                            <div class="media-body text-end icon-state switch-outline">
                                <label class="switch">
                                    <input type="checkbox" checked="" formControlName="hideClientHeader"><span
                                        class="switch-state bg-primary"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">error code</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control p-input" placeholder="error code" id="errorCode"
                            value="" formControlName="errorCode">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">error message</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control p-input" placeholder="error message" id="errorMessage"
                            value="" formControlName="errorMessage">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">enabled</label>
                        <div class="col-sm-9 media-body text-end icon-state switch-outline">
                            <label class="switch">
                                <input type="checkbox" checked="" formControlName="enabled"><span
                                    class="switch-state bg-primary"></span>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-sm btn-success " (click)="submit()"
                        [disabled]="rateLimitingForm.invalid||isLoading">Simpan</button>
                    <button type="button" class="btn btn-sm btn-danger " (click)="close()">Kembali</button>
                </div>
            </div>
        </div>
    </form>
</div>

<!-- Request Termination Modal -->
<div bsModal #reqTerminModal="bs-modal" class="modal fade" id="addPath" backdrop="static" keyboard="false" tabindex="-1"
    role="dialog" aria-labelledby="addModalLabel" aria-hidden="true">
    <form [formGroup]="reqTerminForm">
        <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title " id="addModalLabel">Tambah Request Termination</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <!--sementara di command karena request be dan field kongan berbeda-->
                    <!-- <div class="form-group row">
                        <label class="col-sm-3 col-form-label">consumer</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control p-input" placeholder="consumer" id="consumer"
                                name="consumer" value="" formControlName="consumer">
                        </div>
                    </div> -->
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">status code</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control p-input" placeholder="status code" id="statusCode"
                                name="statusCode" value="" formControlName="statusCode" min="100" max="599">
                            <small class="form-text text-muted" id="emailHelp">Isi dengan number 100-599</small>
                            <!-- <span *ngIf="reqTerminForm.controls.statusCode.invalid && reqTerminForm.controls.statusCode.errors && (reqTerminForm.controls.statusCode.dirty || reqTerminForm.controls.statusCode.touched)">
                                <small class="text-danger" *ngIf="reqTerminForm.controls.statusCode.hasError('pattern')">Only letters, numbers, and special characters !@#$%^&*()_+-= are allowed.</small>
                            </span> -->
                            <span *ngIf="reqTerminForm.controls.statusCode.invalid && reqTerminForm.controls.statusCode.errors && (reqTerminForm.controls.statusCode.dirty || reqTerminForm.controls.statusCode.touched)">
                                <small class="text-danger" *ngIf="reqTerminForm.controls.statusCode.hasError('pattern')"> Only numbers, the characters is not allowed.</small>    
                                <br *ngIf="reqTerminForm.controls.statusCode.hasError('pattern') && reqTerminForm.controls.statusCode.hasError('min')">                            
                                <small class="text-danger" *ngIf="reqTerminForm.controls.statusCode.hasError('min')">statusCode harus lebih besar dari 99.</small> 
                                <br *ngIf="reqTerminForm.controls.statusCode.hasError('pattern') && reqTerminForm.controls.statusCode.hasError('max')">                            
                                <small class="text-danger" *ngIf="reqTerminForm.controls.statusCode.hasError('max')">statusCode harus lebih kecil dari 600.</small>  
                            </span>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">message</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control p-input" placeholder="message" id="message"
                                name="message" value="" formControlName="message">
                                <span *ngIf="reqTerminForm.controls.message.invalid && reqTerminForm.controls.message.errors && (reqTerminForm.controls.message.dirty || reqTerminForm.controls.message.touched)">
                                    <small class="text-danger" *ngIf="reqTerminForm.controls.message.hasError('pattern')">Only letters, numbers, and special characters !@#$%^&*()_+-= are allowed.</small>
                                </span>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">content type</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control p-input" placeholder="content type" id="contentType"
                                name="contentType" value="" formControlName="contentType">
                            <small class="form-text text-muted" id="emailHelp">contoh:
                                "application/json;charset=utf-8"</small>
                                <span *ngIf="reqTerminForm.controls.contentType.invalid && reqTerminForm.controls.contentType.errors && (reqTerminForm.controls.contentType.dirty || reqTerminForm.controls.contentType.touched)">
                                    <small class="text-danger" *ngIf="reqTerminForm.controls.contentType.hasError('pattern')">Only letters, numbers, and special characters !@#$%^&*()_+-= are allowed.</small>
                                </span>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">body</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control p-input" placeholder="body" id="body" name="body"
                                value="" formControlName="body">
                                <span *ngIf="reqTerminForm.controls.body.invalid && reqTerminForm.controls.body.errors && (reqTerminForm.controls.body.dirty || reqTerminForm.controls.body.touched)">
                                    <small class="text-danger" *ngIf="reqTerminForm.controls.body.hasError('pattern')">Only letters, numbers, and special characters !@#$%^&*()_+-= are allowed.</small>
                                </span>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">enabled</label>
                        <div class="col-sm-9 media-body text-end icon-state switch-outline">
                            <label class="switch">
                                <input type="checkbox" checked="" formControlName="enabled"><span
                                    class="switch-state bg-primary"></span>
                            </label>
                        </div>
                    </div>
                    <!-- <div class="form-group row">
                        <label class="col-sm-3 col-form-label">echo</label>
                        <div class="col-sm-9 media-body text-end icon-state switch-outline">
                            <label class="switch">
                                <input type="checkbox" checked="" formControlName="echo"><span
                                    class="switch-state bg-primary"></span>
                            </label>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">trigger</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control p-input" placeholder="trigger" id="trigger" name="trigger"
                                value="" formControlName="trigger">
                        </div>
                    </div> -->
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-sm btn-success " (click)="submit()"
                        [disabled]="reqTerminForm.invalid||isLoading">Simpan</button>
                    <button type="button" class="btn btn-sm btn-danger " (click)="close()">Kembali</button>
                </div>
            </div>
        </div>
    </form>
</div>
<!-- End Request Termination Modal -->

