import { Component,Input,Output,EventEmitter, ViewEncapsulation, ElementRef, HostListener, SimpleChanges, ChangeDetectorRef, ViewChild, TemplateRef  } from '@angular/core';
@Component({
  selector: 'app-request-body-view',
  templateUrl: './request-body-view.component.html',
  styleUrls: ['./request-body-view.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RequestBodyViewComponent {
  @ViewChild('childComponentTemplate') childComponentTemplate: TemplateRef<any>;
  @Input() requestData: any;
  @Input() readonly: boolean =false;
  @Input() isChildren: boolean = false;
  @Input() level: number = 0;
  @Input() dragStatus: boolean = false;
  
  @Output() clickAddChildButton= new EventEmitter();
  @Output() clickEditChildButton= new EventEmitter();
  @Output() clickDeleteChildButton= new EventEmitter();
  @Output() clickRulesChildButton= new EventEmitter();
  
  requestDataTransformed = [];
  requestDataTemp : [];
  showDropZone:boolean=false;

  @HostListener('onAdd', ['$event'])
  onAddEventCaptured(event: any) {
  }

  @HostListener('onEdit', ['$event'])
  onEditEventCaptured(event: any) {
  }

  @HostListener('onDelete', ['$event'])
      onDeleteEventCaptured(event: any) {
  }

  @HostListener('onRules', ['$event'])
  onRulesEventCaptured(event: any) {
  }


  ngOnChanges(changes: SimpleChanges) {        
    //console.log("GenerateParent()");
    //console.log(changes.requestData.currentValue);
    // if(changes.isChildren?.currentValue){
    //   console.log("ISCHILDREN");
    //   console.log(changes.requestData.currentValue);
    // }else{
    //   console.log("NOT ISCHILDREN");
    //   console.log(changes.requestData.currentValue);

    // }
    //this.requestDataTemp = changes.requestData.currentValue;
    //this.requestDataTransformed = this.dataTransform(this.requestDataTemp);

    //console.log(changes.dragStatus?.currentValue);
    //this.showDropZone = changes.dragStatus?.currentValue;
}



// trackByFn(index, item){
//   // return index; 
//   console.log(item.children);
//   return item.children; // or any constant prop
// }

  constructor(private elementRef: ElementRef,private ref: ChangeDetectorRef) {
      //console.log("begin");
      //console.log(this.requestData);
      //console.log("end");

    }

    // generateChild(id){
    //   let t = [];
    //   this.requestDataTemp.forEach(element => {
    //     if(element.parent==id){
    //       t.push({
    //         "id":element.id,
    //         "name":element.name,
    //         "parent":element.parent,
    //         "data_type":element.data_type,
    //         "format":element.format,
    //         "description":element.description,
    //         "mandatory": element.mandatory,
    //         "status":element.status,
    //         "example":element.example,
    //         "children:":this.generateChild(element.id)
    //       })
    //     }
    //   });
    //   return t;
    // }


    onAdd(data): void {
      const event: CustomEvent = new CustomEvent('onAdd', {
        bubbles: true,
        detail: data
      });
      this.elementRef.nativeElement.dispatchEvent(event);
    }

    onEdit(data): void {
      const event: CustomEvent = new CustomEvent('onEdit', {
        bubbles: true,
        detail: data
      });
      this.elementRef.nativeElement.dispatchEvent(event);
    }
    
    
    onDelete(data): void {
      const event: CustomEvent = new CustomEvent('onDelete', {
        bubbles: true,
        detail: data
      });
      this.elementRef.nativeElement.dispatchEvent(event);
    }
    
    onRules(data): void {
      const event: CustomEvent = new CustomEvent('onRules', {
        bubbles: true,
        detail: data
      });
      this.elementRef.nativeElement.dispatchEvent(event);
    }

    addButton(data){
      this.clickAddChildButton.emit(data);
    }

    editButton(data){
      this.clickEditChildButton.emit(data);
    }

    deleteButton(data){
      console.log("From deleteButton");
      this.clickDeleteChildButton.emit(data);
    }


    allowDrop(ev){
      ev.preventDefault();
    }

    dragStop(ev,data){
      this.showDropZone = false;
    }

    drag(ev,data) {
      ev.dataTransfer.setData("source_id", data.id);
      ev.dataTransfer.setData("source_parent", data.parent);
      this.showDropZone = true;
      //this.dragStatus = true;
      //console.log(data);
    }

    drop(ev,d) {
      let data = {
        "source_id":ev.dataTransfer.getData("source_id"),
        "source_parent":ev.dataTransfer.getData("source_parent"),
        "target_id":d.id,
        "target_parent":d.parent,
      }
      ev.preventDefault();
      console.log(data);
    }

    dropSibling(ev,d) {
      let data = {
        "source_id":ev.dataTransfer.getData("source_id"),
        "source_parent":ev.dataTransfer.getData("source_parent"),
        "target_id":d.id,
        "target_parent":d.parent,
      }
      ev.preventDefault();
      console.log(data);
    }

    dropChild(ev,d) {
      let data = {
        "source_id":ev.dataTransfer.getData("source_id"),
        "source_parent":ev.dataTransfer.getData("source_parent"),
        "target_id":d.id,
        "target_parent":d.parent,
      }
      ev.preventDefault();
      console.log(data);
    }
}
