import { Component, ViewEncapsulation, HostListener } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Menu, NavService } from '../../services/nav.service';
import { LayoutService } from '../../services/layout.service';
import { Menus } from 'src/app/shared/components/sidebar/menu';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidebarComponent {

  public iconSidebar;
  public menuItems: Menus[];
  public url: any;
  public fileurl: any;
  public urlTerm:string

  // For Horizontal Menu
  public margin: any = 0;
  public width: any = window.innerWidth;
  public leftArrowNone: boolean = true;
  public rightArrowNone: boolean = false;

  constructor(private router: Router, public navServices: NavService,
    public layout: LayoutService) {
    this.navServices.items.subscribe(menuItems => {
      if(localStorage.getItem('menuInfo')){
        var listmenufromdb = JSON.parse(localStorage.getItem('menuInfo'));
        // console.log(listmenufromdb)

        var menuFromDb =[{url: '/dashboard/default', displayName: 'Beranda', icon: 'home',type: 'link'}] as Menus[];
        listmenufromdb.sort((o1, o2) => o2.orderMenu - o1.orderMenu).forEach(data=> {

          if(!data.read){
            return; 
          }

          if(data.module.parentId){
            this.addItemWithParent(data.module.parentId, data, menuFromDb)
          }else{
            var menu: Menus = {};
            menu.id=data.module.id
            menu.displayName = data.module.displayName;
            menu.name =  data.module.name
            menu.icon=data.module.icon=="-"?null:data.module.icon;
            menu.url=data.module.url;
            menu.type = 'link';
            menu.active=false
            // menu.active=data.module.active
            menu.childrens= []
            menu.children= []

            if (data.module?.headerTitle != null) {
              menu.headTitle1 = data.module.headerTitle;
              menu.type=''
            }
            if (data.module?.headerDesc != null) {
              menu.headTitle2 = data.module.headerDesc;
            }
                    
            if (data.module.active) {
              menuFromDb.push(menu);
            }

            }
          

        });
        // menuFromDb.sort( ( a, b ) => ( a.orderMenu > b.orderMenu) ? 1 : (( b.orderMenu > a.orderMenu ) ? -1 : 0 ));
        this.menuItems= menuFromDb;
        
        
      }else{
        this.menuItems = menuItems;
      } 
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.menuItems.filter(items => {
            if (items.url === event.url) {
              this.urlTerm=event.url
              // this.changeRouterLink(items)
              this.setNavActive(items);
            }
            if (!items.childrens) { return false; }
            items.childrens.filter(subItems => {
              if (subItems.url === event.url) {
                this.urlTerm=event.url
                this.setNavActive(subItems);
              }
              if (!subItems.childrens) { return false; }
              subItems.childrens.filter(subSubItems => {
                if (subSubItems.url === event.url) {
                  this.setNavActive(subSubItems);
                }
              });
            });
          });
        }
      });
    });
  }


  private addItemWithParent(parentId, data, menus) {
    var menu: Menus = {};
    menu.id=data.module.id;
    menu.displayName = data.module.displayName;
    menu.name =  data.module.name
    menu.icon=data.module.icon=="-"?null:data.module.icon;
    menu.url=data.module.url;
    menu.type = 'link';
    menu.active=false
    // menu.active=data.module.active

    

    if (data.module?.headerTitle != null) {
      menu.headTitle1 = data.module.headerTitle;
    }
    if (data.module?.headerDesc != null) {
      menu.headTitle2 = data.module.headerDesc;
    }
    // const newItem = { id: this.items.length + 1, name: newItemName, parentId, children: [] };
  
    // Temukan objek parent berdasarkan parentId
    const parentItem = menus.find(item => item.id === parentId);
  
    // Tambahkan newItem ke dalam children parentItem
    if (parentItem) {
      parentItem.childrens.push(menu);
      parentItem.children.push(menu);

    } else {
      // Handle jika parentId tidak ditemukan
      console.error('Parent dengan id ' + parentId + ' tidak ditemukan.');
    }
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.width = event.target.innerWidth - 500;
  }

  sidebarToggle() {
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
  }


  setMaxWidth(){
    if(this.layout.tenant=='Bank Kalbar'){
      return '120'
    }else if (this.layout.tenant=='Bankaltimtara'){
      return '85'
    }else if(this.layout.tenant=='Bank IBK'){
      return '120'
    }else if(this.layout.tenant=='Bank Banten'){
      return '190'
    }else if(this.layout.tenant=='Bank BJBS'){
      return '90'
    }else{
      return '100'
    }
  }


  // Active Nave state
  setNavActive(item) {
    this.menuItems.filter(menuItem => {
      if (menuItem !== item) {
        menuItem.active = false;
      }
      if (menuItem.childrens && menuItem.childrens.includes(item)) {
        menuItem.active = true;
      }
      if (menuItem.childrens) {
        menuItem.childrens.filter(submenuItems => {
          if (submenuItems.childrens && submenuItems.childrens.includes(item)) {
            menuItem.active = true;
            submenuItems.active = true;
          }
        });
      }
    });
  }

  changeRouterLink(item){
    console.log("KLIK MENU = "+item.active)
    if(this.urlTerm==item.url){
      item.active=true
      window.location.href=item.url
    }else{
      item.active=false
    }
  }
  // Click Toggle menu
  toggletNavActive(item) {
    if (!item.active) {
      this.menuItems.forEach(a => {
        if (this.menuItems.includes(item)) {
          a.active = false;
        }
        if (!a.childrens) { return false; }
        a.childrens.forEach(b => {
          if (a.childrens.includes(item)) {
            b.active = false;
          }
        });
      });
    }
    item.active = !item.active;
  }


  // For Horizontal Menu
  scrollToLeft() {
    if (this.margin >= -this.width) {
      this.margin = 0;
      this.leftArrowNone = true;
      this.rightArrowNone = false;
    } else {
      this.margin += this.width;
      this.rightArrowNone = false;
    }
  }

  scrollToRight() {
    if (this.margin <= -3051) {
      this.margin = -3464;
      this.leftArrowNone = false;
      this.rightArrowNone = true;
    } else {
      this.margin += -this.width;
      this.leftArrowNone = false;
    }
  }
  

}
