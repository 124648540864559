import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { error } from 'jquery';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { SharedService } from 'src/app/components/development/shared.service';
import { ServerService } from 'src/app/service/server.service';
import { environment } from 'src/environments/environment';
declare var require
const Swal = require('sweetalert2')

@Component({
  selector: 'app-traffic-control',
  templateUrl: './traffic-control.component.html',
  styleUrls: ['./traffic-control.component.scss']
})
export class TrafficControlComponent implements OnInit {
  @Output("showModalPlugin") showModalPlugin: EventEmitter<any> = new EventEmitter();
  @Output("reloadDatatables") reloadDatatables: EventEmitter<any> = new EventEmitter();


  @Input() imageSize: any
  @Input() isModal: boolean
  @Input() envi: string
  @Input() id: number
  @Input() fromMenu: string
  key: String
  isEdit: Boolean
  pluginId:String
  tittle:string

  isLoading: boolean

  public reqTerminForm: FormGroup
  public rateLimitingForm: FormGroup

  @ViewChild('reqTerminModal') reqTerminModal: ModalDirective
  @ViewChild('rateLimitingModal') rateLimitingModal: ModalDirective

  reqTerminSave = environment.path.base + environment.path.api_management.base + environment.path.api_management.request_termination
  rateLimitingSave = environment.path.base + environment.path.api_management.base + environment.path.api_management.rate_limiting

  constructor(private fb: FormBuilder,
    private server: ServerService,
    private shared: SharedService) {
    this.reqTerminForm = fb.group({
      consumer: null,
      statusCode:  [null,[Validators.pattern(/^[0-9!@#\$%\^\&*\)\(+=._ -]+$/),Validators.min(100), Validators.max(599)]],
      message:  [null,[Validators.pattern(/^[a-zA-Z0-9!@#\$%\^\&*\)\(+=._ -]+$/)]],
      contentType:  [null,[Validators.pattern(/^[a-zA-Z0-9!@#\$%\^\&*\)\(+=./;_ -]+$/)]],
      body:  [null,[Validators.pattern(/^[a-zA-Z0-9!@#"{}:,[\]\$%\^\&*\)\(+=._ -]+$/)]],
      trigger: null,
      echo: false,
      enabled: false,
    })

    this.rateLimitingForm = fb.group({     
      enabled:[false],
      second: null,      
      minute: null,      
      hour: null,      
      day: null,      
      month: null,      
      year: null,      
      limitBy: null,      
      headerName: null,      
      path: null,      
      policy: null,      
      faultTolerant: [false],      
      redisHost: null,      
      redisPort: null,      
      redisPassword: null,      
      redisUsername: null,      
      redisSsl: [false],      
      redisSslVerify: [false],      
      redisServerName: null,      
      redisTimeout: null,      
      redisDatabase: null,      
      hideClientHeader: null,      
      errorCode: null,      
      errorMessage: null,      
    })

    this.shared.isLoading().subscribe((isLoading) => {
      this.isLoading = isLoading;
    })
  }

  ngOnInit(): void {
  }


  fetchDataModal(key, data){
    this.id= data.service ? data.service.id : data.route ? data.route.id : data.consumer ? data.consumer.id : null
    this.pluginId= data.id
    switch (key) {
      case "reqTermin":
        this.reqTerminForm.controls.consumer.setValue(data.config.consumer)
        this.reqTerminForm.controls.statusCode.setValue(data.config.statusCode)
        this.reqTerminForm.controls.message.setValue(data.config.message)
        this.reqTerminForm.controls.contentType.setValue(data.config.contentType)
        this.reqTerminForm.controls.body.setValue(data.config.body)
        this.reqTerminForm.controls.trigger.setValue(data.config.trigger)
        this.reqTerminForm.controls.echo.setValue(data.config.echo)
        this.reqTerminForm.controls.enabled.setValue(data.enabled)     
        break
      case "rateLimiting":
        this.rateLimitingForm.controls.enabled.setValue(data.enabled)
        this.rateLimitingForm.controls.second.setValue(data.config.second)
        this.rateLimitingForm.controls.minute.setValue(data.config.minute)
        this.rateLimitingForm.controls.hour.setValue(data.config.hour)
        this.rateLimitingForm.controls.day.setValue(data.config.day)
        this.rateLimitingForm.controls.month.setValue(data.config.month)
        this.rateLimitingForm.controls.year.setValue(data.config.year)
        this.rateLimitingForm.controls.limitBy.setValue(data.config.limit_by)
        this.rateLimitingForm.controls.headerName.setValue(data.config.header_name)
        this.rateLimitingForm.controls.path.setValue(data.config.path)
        this.rateLimitingForm.controls.policy.setValue(data.config.policy)
        this.rateLimitingForm.controls.faultTolerant.setValue(data.config.fault_tolerant)
        this.rateLimitingForm.controls.redisHost.setValue(data.config.redis_host)
        this.rateLimitingForm.controls.redisPort.setValue(data.config.redis_port)
        this.rateLimitingForm.controls.redisPassword.setValue(data.config.redis_password)
        this.rateLimitingForm.controls.redisUsername.setValue(data.config.redis_username)
        this.rateLimitingForm.controls.redisSsl.setValue(data.config.redis_ssl)
        this.rateLimitingForm.controls.redisSslVerify.setValue(data.config.redis_ssl_verify)
        this.rateLimitingForm.controls.redisServerName.setValue(data.config.redis_server_name)
        this.rateLimitingForm.controls.redisTimeout.setValue(data.config.redis_timeout)
        this.rateLimitingForm.controls.redisDatabase.setValue(data.config.redis_database)
        this.rateLimitingForm.controls.hideClientHeader.setValue(data.config.hide_client_headers)
        this.rateLimitingForm.controls.errorCode.setValue(data.config.error_code)
        this.rateLimitingForm.controls.errorMessage.setValue(data.config.error_message)
        break
      default:
        console.log("No such key exist!")
        break
    }
  }

  showModalAndSetForm(key){
    switch (key) {
      case "reqTermin":
        this.reqTerminModal.show()
        break
      case "rateLimiting":
        this.rateLimitingModal.show()
        break
      default:
        console.log("No such key exist!")
        break
    }
  }

  showAddModal(key, data) {
    this.key = key
    if(data!=null){
      this.isEdit=true
      this.tittle='UBAH'
      this.fetchDataModal(key,data)
    }else{
      this.tittle='TAMBAH'
    }
    this.showModalAndSetForm(key)
  }

  submit() {
    switch (this.key) {
      case "reqTermin":
        this.submitReqTermin()

        break
      case "reqTermin":
        this.submitReqTermin()

        break
      case "rateLimiting":
        this.submitRateLimiting()
  
          break
      default:
        console.log("No such key exist!")
        break
    }
  }

  addReqTermin(jsonData){
    let url = this.fromMenu == "services" && this.id ? this.reqTerminSave + '/service/' + this.id : this.fromMenu == "routes" && this.id ? this.reqTerminSave + '/route/' + this.id : this.fromMenu == "consumers" && this.id ? this.reqTerminSave+environment.path.api_management.consumers + '/' + this.id : this.reqTerminSave
      this.server.doPostWithEnvi(jsonData, url, this.envi).subscribe(async resp => {
        if (resp) {
          Swal.fire('Success!', resp['message'], 'success').then((result) => {
            if (result.isConfirmed) {
              this.close()
            }
          });
        } else {
          Swal.fire('Failure!', resp['message'], 'error')
        }
      })
  }

  editReqTermin(jsonData){
    let url = this.fromMenu == "services" && this.id ? this.reqTerminSave+'/'+this.pluginId + '/service/' + this.id : this.fromMenu == "routes" && this.id ? this.reqTerminSave+'/'+this.pluginId + '/route/' + this.id : this.fromMenu == "consumers" && this.id ? this.reqTerminSave+'/'+this.pluginId+environment.path.api_management.consumers + '/' + this.id : this.reqTerminSave+'/'+this.pluginId
      this.server.doPutWithEnvi(jsonData, url, this.envi).subscribe(async resp => {
        if (resp) {
          Swal.fire('Success!', resp['message'], 'success').then((result) => {
            if (result.isConfirmed) {
              this.close()
            }
          });
        } else {
          Swal.fire('Failure!', resp['message'], 'error')
        }
      })
  }


  submitRateLimiting() {
    let data = this.rateLimitingForm.value
    let jsonData = {
      enabled:data.enabled,
      second : data.second,
      minute : data.minute,
      hour : data.hour,
      day : data.day,
      month : data.month,
      year : data.year,
      limit_by : data.limitBy,
      header_name : data.headerName,
      path : data.path,
      policy : data.policy,
      fault_tolerant : data.faultTolerant,
      redis_host : data.redisHost,
      redis_port : data.redisPort,
      redis_password : data.redisPassword,
      redis_username : data.redisUsername,
      redis_ssl : data.redisSsl,
      redis_ssl_verify : data.redisSslVerify,
      redis_server_name : data.redisServerName,
      redis_timeout : data.redisTimeout,
      redis_database : data.redisDatabase,
      hide_client_headers : data.hideClientHeader,
      error_code : data.errorCode,
      error_message : data.errorMessage,
    }
    if(!this.isEdit){
      this.addRateLimiting(jsonData)
    }else{
      this.editRateLimiting(jsonData)
    }
  }

  addRateLimiting(jsonData){
    let url = this.fromMenu == "services" && this.id ? this.rateLimitingSave + '/service/' + this.id : this.fromMenu == "routes" && this.id ? this.rateLimitingSave + '/route/' + this.id : this.fromMenu == "consumers" && this.id ? this.rateLimitingSave+environment.path.api_management.consumers + '/' + this.id : this.rateLimitingSave
      this.server.doPostWithEnvi(jsonData, url, this.envi).subscribe(async resp => {
        if (resp) {
          Swal.fire('Success!', resp['message'], 'success').then((result) => {
            if (result.isConfirmed) {
              this.close()
            }
          });
        } else {
          Swal.fire('Failure!', resp['message'], 'error')
        }
      })
  }

  editRateLimiting(jsonData){
    let url = this.fromMenu == "services" && this.id ? this.rateLimitingSave+'/'+this.pluginId + '/service/' + this.id : this.fromMenu == "routes" && this.id ? this.rateLimitingSave+'/'+this.pluginId + '/route/' + this.id : this.fromMenu == "consumers" && this.id ? this.rateLimitingSave+'/'+this.pluginId+environment.path.api_management.consumers + '/' + this.id : this.rateLimitingSave+'/'+this.pluginId
      this.server.doPutWithEnvi(jsonData, url, this.envi).subscribe(async resp => {
        if (resp) {
          Swal.fire('Success!', resp['message'], 'success').then((result) => {
            if (result.isConfirmed) {
              this.close()
            }
          });
        } else {
          Swal.fire('Failure!', resp['message'], 'error')
        }
      })
  }


  submitReqTermin() {
    let data = this.reqTerminForm.value
    if (this.validateReqTermin()) {
      let jsonData = {
        "enabled": data.enabled,
        "statusCode": data.statusCode,
        "message": data.message?data.message:null,
        "body":data.body,
        "contentType": data.contentType
      }
      if(!this.isEdit){
        this.addReqTermin(jsonData)
      }else{
        this.editReqTermin(jsonData)
      }
    }
  }

  validateReqTermin() {
    let isNext = true
    let isMessage = this.reqTerminForm.value.message
    let isBody = this.reqTerminForm.value.body
    let isContentType = this.reqTerminForm.value.contentType
    if (isMessage && isBody) {
      isNext = false
      Swal.fire('Failure!', 'Harap isi salah satu saja antara field Message atau Body.', 'error')
    }

    if (isBody && (isContentType == null || isContentType == "")) {
      isNext = false
      Swal.fire('Failure!', 'Harap isi field Content Type.', 'error')
    }

    return isNext
  }

  close() {
    if(!this.isEdit){
      this.showModalPlugin.emit()
    }else{
      this.reloadDatatables.emit()
    }
    this.reqTerminModal.hide()
    this.rateLimitingModal.hide()
    this.isEdit= false
  }

}
