import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { SharedService } from 'src/app/components/development/shared.service';
import { ServerService } from 'src/app/service/server.service';
import { environment } from 'src/environments/environment';

declare var require
const Swal = require('sweetalert2')


@Component({
  selector: 'app-security',
  templateUrl: './security.component.html',
  styleUrls: ['./security.component.scss']
})
export class SecurityComponent implements OnInit {
  @Input() imageSize: any
  @Input() isModal: boolean
  @Input() envi: string
  @Input() id: number;
  @Input() fromMenu: string;
  tittle: string

  public aclForm: FormGroup
  public corsForm: FormGroup
  public ipRestForm: FormGroup
  public botDetectionForm: FormGroup
  key: String
  pluginId:string
  isEdit:Boolean = false

  isLoading: boolean




  @ViewChild('aclModal') aclModal: ModalDirective
  @ViewChild('corsModal') corsModal: ModalDirective
  @ViewChild('ipRestModal') ipRestModal: ModalDirective
  @ViewChild('botDetectionModal') botDetectionModal: ModalDirective
  @Output("showModalPlugin") showModalPlugin: EventEmitter<any> = new EventEmitter();
  @Output("reloadDatatables") reloadDatatables: EventEmitter<any> = new EventEmitter();



  corsSaveUrl = environment.path.base + environment.path.api_management.base + environment.path.api_management.cors
  aclSaveUrl = environment.path.base + environment.path.api_management.base + environment.path.api_management.acl
  ipRestictionSaveUrl = environment.path.base + environment.path.api_management.base + environment.path.api_management.ip_restriction
  botDetectionUrl = environment.path.base + environment.path.api_management.base + environment.path.api_management.bot_detection

  constructor(private fb: FormBuilder,
    private server: ServerService,
    private shared: SharedService) {
    this.aclForm = fb.group({
      consumer: [null],
      allow: [null],
      deny: [null],
      hideGroupHeaders: false,
      enabled: false
    })

    this.corsForm = fb.group({
      origins:  [[],[Validators.pattern(/^[a-zA-Z0-9!@#\$%\^\&*\)\(+=.,_ -]+$/)]],
      headers:  [[],[Validators.pattern(/^[a-zA-Z0-9!@#\$%\^\&*\)\(+=.,_ -]+$/)]],
      exposedHeaders:  [[],[Validators.pattern(/^[a-zA-Z0-9!@#\$%\^\&*\)\(+=.,_ -]+$/)]],
      methods:  [[],[Validators.pattern(/^[a-zA-Z0-9!@#\$%\^\&*\)\(+=.,_ -]+$/)]],
      maxAge: [null,[Validators.pattern(/^[0-9]+$/),Validators.min(0)]],
      credentials: false,
      preflightContinue: false,
      enabled: false
    })

    this.ipRestForm = fb.group({
      allow: [null, [Validators.required, Validators.pattern('^[0-9-./,]*')]],
      deny: [null, [Validators.required, Validators.pattern('^[0-9-./,]*')]],
      status: [null,[ Validators.pattern('[0-9]*')]],
      message: [null],
      enabled: false
    })

    this.botDetectionForm = fb.group({
      consumer: [null],
      allow: [null],
      deny: [null],
      enabled: false
    })

    this.shared.isLoading().subscribe((isLoading) => {
      this.isLoading = isLoading;
    })
  }

  ngOnInit(): void {
  }

  fetchDataModal(key, data){
    this.id= data.service ? data.service.id : data.route ? data.route.id : data.consumer ? data.consumer.id : null
    this.pluginId= data.id
    switch (key) {
      case "acl":
        this.aclForm.controls.consumer.setValue(data.config.consumer)
        this.aclForm.controls.allow.setValue(data.config.allow?data.config.allow.join(','):null)
        this.aclForm.controls.deny.setValue(data.config.deny?data.config.deny.join(','):null)
        this.aclForm.controls.hideGroupHeaders.setValue(data.config.hideGroupsHeader)
        this.aclForm.controls.enabled.setValue(data.enabled)
        break
      case "cors":
        this.corsForm.controls.origins.setValue(data.config.origins?data.config.origins.join(','):null)
        this.corsForm.controls.headers.setValue(data.config.headers?data.config.headers.join(','):null)
        this.corsForm.controls.exposedHeaders.setValue(data.config.exposedHeaders?data.config.exposedHeaders.join(','):null)
        this.corsForm.controls.methods.setValue(data.config.methods?data.config.methods.join(','):null)
        this.corsForm.controls.maxAge.setValue(data.config.maxAge)
        this.corsForm.controls.credentials.setValue(data.config.credentials)
        this.corsForm.controls.preflightContinue.setValue(data.config.preflightContinue)
        this.corsForm.controls.enabled.setValue(data.enabled)
        break
      case "ipRestriction":
        this.ipRestForm.controls.allow.setValue(data.config.allow?data.config.allow.join(','):null)
        this.ipRestForm.controls.deny.setValue(data.config.deny?data.config.deny.join(','):null)
        this.ipRestForm.controls.status.setValue(data.config.status)
        this.ipRestForm.controls.message.setValue(data.config.message)
        this.ipRestForm.controls.enabled.setValue(data.enabled)
        if(data.config && data.config.allow){
          this.ipRestForm.controls.deny.clearValidators()
          this.ipRestForm.controls.deny.addValidators(Validators.pattern('^[0-9-./,]*'));
        }
        if(data.config && data.config.deny){
          this.ipRestForm.controls.allow.clearValidators()
          this.ipRestForm.controls.allow.addValidators(Validators.pattern('^[0-9-./,]*'));

        }

        this.ipRestForm.controls.deny.updateValueAndValidity()
        this.ipRestForm.controls.allow.updateValueAndValidity()
        break
      case "botDetection":
        this.botDetectionForm.controls.consumer.setValue(data.config.consumer)
        this.botDetectionForm.controls.allow.setValue(data.config.allow?data.config.allow.join(','):null)
        this.botDetectionForm.controls.deny.setValue(data.config.deny?data.config.deny.join(','):null)
        this.botDetectionForm.controls.enabled.setValue(data.enabled)
        break
      default:
        console.log("No such key exist!")
        break
    }
  }

  showModalAndSetForm(key){
    switch (key) {
      case "acl":
        this.aclModal.show()
        break
      case "cors":
        this.corsModal.show()
        break
      case "ipRestriction":
        this.ipRestModal.show()
        break
      case "botDetection":
        this.botDetectionModal.show()
        break
      default:
        console.log("No such key exist!")
        break
    }
  }
  showAddModal(key, data) {
    this.key = key
    if(data!=null){
      this.isEdit=true
      this.tittle= 'UBAH'
      this.fetchDataModal(key,data)
    }else{
      this.tittle='TAMBAH'
    }
    this.showModalAndSetForm(key)
  }

  checkAlloworDeny() {
    let isNext = true
    let isAllow = this.aclForm.value.allow ? true : false
    let isDeny = this.aclForm.value.deny ? true : false
    if (isAllow && isDeny) {
      isNext = false
      Swal.fire('Failure!', 'Harap Isi Salah Satu Saja Antara Field Allow atau Deny.', 'error')
    } else if (!isAllow && !isDeny) {
      isNext = false
      Swal.fire('Failure!', 'Harap Isi Salah Satu Antara Field Allow atau Deny.', 'error')
    }
    return isNext
  }


  addCors(jsonData){
    let url = this.fromMenu == "services" && this.id ? this.corsSaveUrl + '/service/' + this.id : this.fromMenu == "routes" && this.id ? this.corsSaveUrl + '/route/' + this.id : this.corsSaveUrl
    this.server.doPostWithEnvi(jsonData, url, this.envi).subscribe(async resp => {
      if (resp) {
        Swal.fire('Success!', resp['message'], 'success').then((result) => {
          if (result.isConfirmed) {
            this.close()
          }
        });
      } else {
        Swal.fire('Failure!', resp['message'], 'error')
      }
    })
  }

  editCors(jsonData){
    let url = this.fromMenu=="services" && this.id ? this.corsSaveUrl+'/'+this.pluginId+'/service/'+this.id : this.fromMenu=="routes" && this.id?this.corsSaveUrl+'/'+this.pluginId+'/route/'+this.id:this.corsSaveUrl+'/'+this.pluginId
    this.server.doPutWithEnvi(jsonData, url, this.envi).subscribe(async resp => {
      if (resp) {
        Swal.fire('Success!', resp['message'], 'success').then((result) => {
          if (result.isConfirmed) {
            this.close()
          }
        });
      } else {
        Swal.fire('Failure!', resp['message'], 'error')
      }
    })
  }

  submitCors() {
    let data = this.corsForm.value
    let jsonData = {
      enabled: data.enabled,
      origins: data.origins ? data.origins.split(",") : null,
      headers: data.headers ? data.headers.split(",") : null,
      methods: data.methods ? data.methods.split(",") : null,
      exposedHeaders: data.exposedHeaders ? data.exposedHeaders.split(",") : null,
      maxAge: data.maxAge,
      credentials: data.credentials,
      preflightContinue: data.preflightContinue,
    }
    if(!this.isEdit){
      this.addCors(jsonData)
    }else{
      this.editCors(jsonData)
    }
  }


  addAcl(jsonData){
    let url = this.fromMenu == "services" && this.id && this.id ? this.aclSaveUrl + '/service/' + this.id : this.fromMenu == "routes" && this.id ? this.aclSaveUrl + '/route/' + this.id :this.aclSaveUrl
    this.server.doPostWithEnvi(jsonData, url, this.envi).subscribe(async resp => {
      if (resp) {
        Swal.fire('Success!', resp['message'], 'success').then((result) => {
          if (result.isConfirmed) {
            this.close()
          }
        });
      } else {
        Swal.fire('Failure!', resp['message'], 'error')
      }
    })
  }

  editAcl(jsonData){
    let url = this.fromMenu=="services" && this.id ? this.aclSaveUrl+'/'+this.pluginId+'/service/'+this.id : this.fromMenu=="routes" && this.id?this.aclSaveUrl+'/'+this.pluginId+'/route/'+this.id:this.aclSaveUrl+'/'+this.pluginId
    this.server.doPutWithEnvi(jsonData, url, this.envi).subscribe(async resp => {
      if (resp) {
        Swal.fire('Success!', resp['message'], 'success').then((result) => {
          if (result.isConfirmed) {
            this.close()
          }
        });
      } else {
        Swal.fire('Failure!', resp['message'], 'error')
      }
    })
  }

  submitAcl() {
    let data = this.aclForm.value
    if (this.checkAlloworDeny()) {
      let jsonData = {
        enabled: data.enabled,
        allow: data.allow ? data.allow.split(",") : [], // harus keisi salah satu nya antara allow atau deny
        deny: data.deny ? data.deny.split(",") : [],
        hideGroupsHeader: data.hideGroupHeaders,
      }
      if(!this.isEdit){
        this.addAcl(jsonData)
      }else{
        this.editAcl(jsonData)
      }
    }
  }

  addIpRest(jsonData){
    let url = this.fromMenu == "services" && this.id && this.id ? this.ipRestictionSaveUrl + '/service/' + this.id : this.fromMenu == "routes" && this.id ? this.ipRestictionSaveUrl + '/route/' + this.id :  this.fromMenu=="consumers" && this.id?this.ipRestictionSaveUrl+'/consumer/'+this.id :this.ipRestictionSaveUrl
    this.server.doPostWithEnvi(jsonData, url, this.envi).subscribe(async resp => {
      if (resp) {
        Swal.fire('Success!', resp['message'], 'success').then((result) => {
          if (result.isConfirmed) {
            this.close()
          }
        });
      } else {
        Swal.fire('Failure!', resp['message'], 'error')
      }
    })
  }

  editIpRest(jsonData){
    let url = this.fromMenu == "services" && this.id && this.id ? this.ipRestictionSaveUrl+'/'+this.pluginId + '/service/' + this.id : this.fromMenu == "routes" && this.id ? this.ipRestictionSaveUrl+'/'+this.pluginId + '/route/' + this.id :  this.fromMenu=="consumers" && this.id?this.ipRestictionSaveUrl+'/'+this.pluginId+'/consumer/'+this.id :this.ipRestictionSaveUrl+'/'+this.pluginId
    this.server.doPutWithEnvi(jsonData, url, this.envi).subscribe(async resp => {
      if (resp) {
        Swal.fire('Success!', resp['message'], 'success').then((result) => {
          if (result.isConfirmed) {
            this.close()
          }
        });
      } else {
        Swal.fire('Failure!', resp['message'], 'error')
      }
    })
  }

  submitIpRest() {
    let data = this.ipRestForm.value
      let jsonData = {
        enabled: data.enabled,
        allow: data.allow ? data.allow.split(",") : [],
        deny: data.deny ? data.deny.split(",") : [],
        status: data.status?data.status:"",
        message: data.message?data.message:""
      }
      if(!this.isEdit){
        this.addIpRest(jsonData)
      }else{
        this.editIpRest(jsonData)
      }
  }

  addBotDetection(jsonData){
    let url = this.fromMenu == "services" && this.id && this.id ? this.botDetectionUrl + '/service/' + this.id : this.fromMenu == "routes" && this.id ? this.botDetectionUrl + '/route/' + this.id :  this.fromMenu=="consumers" && this.id?this.botDetectionUrl+'/consumer/'+this.id :this.botDetectionUrl
    this.server.doPostWithEnvi(jsonData, url, this.envi).subscribe(async resp => {
      if (resp) {
        Swal.fire('Success!', resp['message'], 'success').then((result) => {
          if (result.isConfirmed) {
            this.close()
          }
        });
      } else {
        Swal.fire('Failure!', resp['message'], 'error')
      }
    })
  }

  editBotDetection(jsonData){
    let url = this.fromMenu == "services" && this.id && this.id ? this.botDetectionUrl+'/'+this.pluginId + '/service/' + this.id : this.fromMenu == "routes" && this.id ? this.botDetectionUrl+'/'+this.pluginId + '/route/' + this.id :  this.fromMenu=="consumers" && this.id?this.botDetectionUrl+'/'+this.pluginId+'/consumer/'+this.id :this.botDetectionUrl+'/'+this.pluginId
    this.server.doPutWithEnvi(jsonData, url, this.envi).subscribe(async resp => {
      if (resp) {
        Swal.fire('Success!', resp['message'], 'success').then((result) => {
          if (result.isConfirmed) {
            this.close()
          }
        });
      } else {
        Swal.fire('Failure!', resp['message'], 'error')
      }
    })
  }

  submitBotDetection() {
    let data = this.botDetectionForm.value
      let jsonData = {
        enabled: data.enabled,
        allow: data.allow ? data.allow.split(",") : [],
        deny: data.deny ? data.deny.split(",") : [],
      }
      if(!this.isEdit){
        this.addBotDetection(jsonData)
      }else{
        this.editBotDetection(jsonData)
      }
  }

  submit() {
    switch (this.key) {
      case "acl":
        this.submitAcl()
        break
      case "cors":
        this.submitCors()
        break
      case "ipRestriction":
        this.submitIpRest()
        break
      case "botDetection":
        this.submitBotDetection()
        break
      default:
        console.log("No such key exist!")
        break
    }

  }

  close() {
    if(!this.isEdit){
      this.showModalPlugin.emit()
    }else{
      this.reloadDatatables.emit()
    }
    this.aclModal.hide()
    this.corsModal.hide()
    this.ipRestModal.hide()
    this.botDetectionModal.hide()
    this.aclForm.reset()
    this.corsForm.reset()
    this.ipRestForm.reset()
    this.botDetectionForm.reset()
    this.isEdit=false
  }


  onBlur(event, action){
    const value = event.target.value
    switch (action) {
      case 'allow':
        if(value){
          this.ipRestForm.controls.deny.clearValidators()
          this.ipRestForm.controls.deny.addValidators(Validators.pattern('^[0-9-./,]*'));
        } else{
          this.ipRestForm.controls.deny.addValidators([Validators.required,Validators.pattern('^[0-9-./,]*')])
        }
        this.ipRestForm.controls.deny.updateValueAndValidity()
      break;
      case 'deny':
        if(value){
          this.ipRestForm.controls.allow.clearValidators()
          this.ipRestForm.controls.allow.addValidators(Validators.pattern('^[0-9-./,]*'));

        } else{
          this.ipRestForm.controls.allow.addValidators([Validators.required,Validators.pattern('^[0-9-./,]*')])
        }
        this.ipRestForm.controls.allow.updateValueAndValidity()
      break;

      default:
        console.log("No menu Choosen!!!");
        break;
    }
  }

}
