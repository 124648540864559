import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SharedService } from '../components/development/shared.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {
  constructor(public router:Router, public shared: SharedService){

  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let user = JSON.parse(localStorage.getItem('userInfo'));
    if(user){
      return true
    }
    this.router.navigate(['auth/login']);
    return false;
  }


  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let menus = JSON.parse(localStorage.getItem('menuInfo'));
    const listMenuAccess = menus.filter(menu => menu.read === true)
    const menuClickEvent = listMenuAccess.filter(menu => state.url.includes(menu.module.url)) 
    if(listMenuAccess.some(menu => state.url.includes(menu.module.url))){
      // cek have privillage for write or no
      if(listMenuAccess.some(menu => state.url.includes(menu.module.url) && menu.write)){
        this.shared.showWrite()
      }else{
        this.shared.hideWrite()
      }
      // cek have privillage for delete or no
      if(listMenuAccess.some(menu => state.url.includes(menu.module.url) && menu.delete)){
        this.shared.showDelete()
      }else{
        this.shared.hideDelete()
      }
      return true
    }
    this.router.navigate(['error']);
    return false;
  }

}
