import { catchError, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpResponse, HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { Router } from '@angular/router';

import Swal from "sweetalert2";
import { Observable, throwError } from 'rxjs';
import { SharedService } from '../components/development/shared.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from '../shared/services/modal.service';
import { environment } from 'src/environments/environment';
import { ServerService } from './server.service';

declare var require
// const Swal = require('sweetalert2')

@Injectable({
  providedIn: 'root'
})
export class AuthHttpInterceptorService implements HttpInterceptor {

  constructor(private router: Router,
    private shared: SharedService,
    private modalService: ModalService,
    private ngbModalService: NgbModal,
    private serverService: ServerService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.shared.showLoading();
    if (localStorage.getItem('userInfo') && localStorage.getItem('token')) {
      var userInfo = JSON.parse(localStorage.getItem('userInfo'));   
      if(userInfo){
        req = req.clone({
          setHeaders: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
            "X-USER-ID": userInfo?String(userInfo.id):'' 
          },
        });
      }else{
        req = req.clone({
          setHeaders: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        });
      }
    }
    // console.log(req);
    return next.handle(req).pipe(tap(event => {
      if (event instanceof HttpResponse) {
        if (event.body && event.status) {
          this.shared.hideLoading();
          if (event.body.token != null) {
            localStorage.setItem('token', event.body.token);
          }
          if (event.body.data?event.body.data.error != null:false) {
            Swal.fire('Failure!', event.body.data.error, 'error');
          }
        }
      }
    }), catchError((err, caught) => {
      this.shared.hideLoading();
      let errmessage =""
      if (err.status == 401) {
        let tittleError = "Session Timeout"
        let textError ="Sesi telah habis akun anda akan ter-logout otomatis."
        if(err.url.includes("/bank/login")){
          textError=err.error?err.error.message : err.message
          tittleError="Error"
        }
        Swal.fire({
          title: tittleError,
          text: textError,
          icon: 'error',
          confirmButtonText: 'OK'
        }).then((result) => {
          if (result.isConfirmed) {
            this.resetPage()
          }
        });
      }else if (err.status == 403) {
          this.router.navigate(['/error']);
      }else {
        if (err.error != undefined) {
          errmessage = err.error['message']
          Swal.fire('Failure!', err.error['message'], 'error');
        } else {
          errmessage = err['message']
          Swal.fire('Failure!', err['message'], 'error');
        }
      }
      return throwError(errmessage?errmessage:err);
    }));
  }

  private resetPage(){
    sessionStorage.clear()
    localStorage.clear()
    this.modalService.closeModal()
    this.ngbModalService.dismissAll()
    this.router.navigate(['/auth/login']);
  }
}
