<!-- 
    <ul  class="{{isChildren ? 'sub-menu' : 'main'}}" *ngFor="let data of requestData" class="parent-ul">
        <li class="item"> 
            <div class="row itemRequest">
                <div class="col-sm-4">
                    <strong>{{data.name}}</strong>
                </div>
                <div class="col-sm-2">
                    <span class=" float-right">&nbsp;&nbsp;</span>
                    <button class="btn btn-xs btn-outline-danger float-right"  (click)="onDelete(data)" *ngIf="data.children?.length < 1" ><i class="fa fa-remove"></i></button>
                    <span class=" float-right">&nbsp;&nbsp;</span>
                    <button class="btn btn-xs btn-outline-info float-right" (click)="onEdit(data)"><i class="fa fa-pencil"></i></button> 
                    <span class=" float-right">&nbsp;&nbsp;</span>
                    <button class="btn btn-xs btn-outline-light float-right" (click)="onAdd(data)"><i class="fa fa-plus"></i></button> 
                    <span class=" float-right">&nbsp;&nbsp;</span>
                </div>
                <div class="col-sm-6" *ngIf="data.children?.length < 1">    
                        <div style="width:auto; border:thin groove grey;" class="float-left">test</div>
                        <span class="badge rounded-pill badge-dark text-light float-right">Mandatory :  <i *ngIf="!data.mandatory" class="fa fa-ban" style="color:red;"></i><i *ngIf="data.mandatory" class="fa fa-check" style="color:green;"></i></span>
                        <span class="badge rounded-pill badge-dark text-light float-right">Active :  <i *ngIf="!data.status" class="fa fa-ban" style="color:red;"></i><i *ngIf="data.status" class="fa fa-check" style="color:green;"></i></span>
                        <span class="badge rounded-pill badge-dark text-light float-right">Data Type : {{data.dataType}}</span>
                        <span class="badge rounded-pill badge-dark text-light float-right">Format : {{data.format}}</span>
                        <span class="badge rounded-pill badge-dark text-light float-right">Example :  {{data.example}}</span>
                </div>
            </div>
            <app-request-body-view  [requestData]="data.children" [readonly]="false" [isChildren]="true"></app-request-body-view>
        </li>
    </ul>                                        -->
    <ng-container  *ngIf="!isChildren" >
        <table class="table table-bordered  table-sm">
            <tr class="itemRequest">
                <th>Field Name</th>
                <th>Data Type</th>
                <th>Format</th>
                <th>Mandatory</th>
                <th>Status</th>
                <th>Action</th>
            </tr>
            <tr *ngIf="requestData?.length < 1">
                <td colspan="6" class="text-center" > No Data
                </td>
            </tr>
            <ng-container  *ngFor="let data of requestData" >
                <tr >
                    <td >
                        {{data.name}}
                    </td>
                    <td>
                        <ng-container  *ngIf="data.children?.length < 1">
                            {{data.dataType}}
                        </ng-container>
                    </td>
                    <td>
                        <ng-container  *ngIf="data.children?.length < 1">
                            {{data.format}}
                        </ng-container>
                    </td>
                    <td>
                        <ng-container  *ngIf="data.children?.length < 1">
                            <i *ngIf="!data.mandatory" class="fa fa-ban" style="color:red;"></i><i *ngIf="data.mandatory" class="fa fa-check" style="color:green;"></i>
                        </ng-container>
                    </td>
                    <td>
                        <ng-container  *ngIf="data.children?.length < 1">
                            <i *ngIf="!data.active" class="fa fa-ban" style="color:red;"></i><i *ngIf="data.active" class="fa fa-check" style="color:green;"></i>
                        </ng-container>
                    </td>
                    <td>
                            <span class="linkAction pointer" (click)="onAdd(data)" *ngIf="!readonly && data.dataType.includes('OBJECT')"><i class="fa fa-plus"></i> Child</span> &nbsp;
                            <span class="linkAction pointer" (click)="onEdit(data)" *ngIf="!readonly"><i class="fa fa-pencil" *ngIf="!readonly"></i> Edit</span> &nbsp;
                            <span class="linkAction pointer" (click)="onRules(data)" *ngIf="data.children?.length < 1 "><i class="fa fa-navicon"></i> Rules</span> &nbsp;
                            <span class="linkAction pointer" (click)="onDelete(data)" *ngIf="data.children?.length < 1 && readonly==false"><i class="fa fa-remove"></i> Delete</span> &nbsp;
                            
                    </td>
                </tr>
                
                <app-request-body-view style="display: contents"  [requestData]="data.children" [readonly]="readonly" [isChildren]="true" [level]="level+1" [dragStatus]="showDropZone"></app-request-body-view>
               
            </ng-container>

            
        </table>
    </ng-container>
    <ng-container  *ngIf="isChildren" >
        <ng-container  *ngFor="let data of requestData" >
            <tr >
                <td>
                    <ng-container *ngFor="let n of [].constructor(level)">
                        <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
                    </ng-container>
                    {{data.name}}
                </td>
                <td>
                    <ng-container  *ngIf="data.children?.length < 1">
                        {{data.dataType}}
                    </ng-container>
                </td>
                <td>
                    <ng-container  *ngIf="data.children?.length < 1">
                        {{data.format}}
                    </ng-container>
                </td>
                <td>
                    <ng-container  *ngIf="data.children?.length < 1">
                        <i *ngIf="!data.mandatory" class="fa fa-ban" style="color:red;"></i><i *ngIf="data.mandatory" class="fa fa-check" style="color:green;"></i>
                    </ng-container>
                </td>
                <td>
                    <ng-container  *ngIf="data.children?.length < 1">
                        <i *ngIf="!data.active" class="fa fa-ban" style="color:red;"></i><i *ngIf="data.active" class="fa fa-check" style="color:green;"></i>
                    </ng-container>
                </td>               
                <td>
                    <span class="linkAction pointer" (click)="onAdd(data)" *ngIf="!readonly && data.dataType.includes('OBJECT')"><i class="fa fa-plus"></i> Child</span> &nbsp;
                    <span class="linkAction pointer" (click)="onEdit(data)" *ngIf="!readonly"><i class="fa fa-pencil" *ngIf="!readonly"></i> Edit</span> &nbsp;
                    <span class="linkAction pointer" (click)="onRules(data)" *ngIf="data.children?.length < 1 "><i class="fa fa-navicon"></i> Rules</span> &nbsp;
                    <span class="linkAction pointer" (click)="onDelete(data)" *ngIf="data.children?.length < 1 && readonly==false"><i class="fa fa-remove"></i> Delete</span> &nbsp;
                       
                </td>
            </tr>
            <app-request-body-view  style="display: contents" [requestData]="data.children" [readonly]="readonly" [isChildren]="true" [level]="level+1" [dragStatus]="showDropZone"></app-request-body-view>
            
        </ng-container>
    </ng-container>
