import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ServerService {
    private readonly url: string;

    constructor(private http: HttpClient) {
        this.url = '';
    }

    // tslint:disable-next-line: typedef
    private errorHandler(error: HttpErrorResponse) {
        return observableThrowError(error || 'Server Error');
    }

    doGet(url: string): Observable<any> {
        const header = new HttpHeaders();
        const path = this.url + url;
        header.append('Content-Type', 'applications/json');
        return this.http.get(path, {
            headers: header
        }).pipe(catchError(this.errorHandler));
    }

    doGetWithEnvi(url: string, envi:string): Observable<any> {
        const path = this.url + url;
        return this.http.get(path, {
            headers: {'x-environment': envi}
        }).pipe(catchError(this.errorHandler));
    }

    doGetFile(url: string): Observable<any> {
        const header = new HttpHeaders();
        const path = this.url + url;
        header.append('Content-Type', 'application/octet-stream');
        return this.http.get(path, {
            headers: header,
            responseType: 'blob'
        }).pipe(catchError(this.errorHandler));
    }

    // getImage(url: string): Observable<any> {
    //     const path = this.url + url;
    //     return this.http.get(path, {
    //         responseType: 'blob'
    //     }).pipe(catchError(this.errorHandler));
    // }

    doPost(post: any, url: string): Observable<any> {
        const header = new HttpHeaders();
        const path = url;
        header.append('Content-Type', 'applications/json');
        return this.http.post(path, post, {
            headers: header
        }).pipe(catchError(this.errorHandler));
    }

    doPostWithEnvi(post: any, url: string, envi: string): Observable<any> {
        const path = url;
        return this.http.post(path, post, {
            headers: {'x-environment': envi}
        }).pipe(catchError(this.errorHandler));
    }

    doPut(put: any, url: string): Observable<any> {
        const header = new HttpHeaders();
        const path = url;
        header.append('Content-Type', 'applications/json');
        return this.http.put(path, put, {
            headers: header
        }).pipe(catchError(this.errorHandler));
    }

    doPutWithEnvi(put: any, url: string, envi: string): Observable<any> {
        const header = new HttpHeaders();
        const path = url;
        return this.http.put(path, put, {
            headers: {'x-environment': envi}
        }).pipe(catchError(this.errorHandler));
    }

    doPatch(patch: any, url: string): Observable<any> {
        const header = new HttpHeaders();
        const path = url;
        return this.http.patch(path, patch,  {
            headers: header
        }).pipe(catchError(this.errorHandler));
    }

    doPatchWithEnvi(patch: any, url: string, envi: string): Observable<any> {
        const path = url;
        return this.http.patch(path, patch, {
            headers: {'x-environment': envi}
        }).pipe(catchError(this.errorHandler));
    }

    doDelete(id: number, url: string): Observable<any> {
        const header = new HttpHeaders();
        const path = this.url + url;
        header.append('Content-Type', 'applications/json');
        return this.http.delete(path + '/' + id, {
            headers: header
        }).pipe(catchError(this.errorHandler));
    }

    doDeleteWithEnvi(id: number, url: string, envi: string): Observable<any> {
        const path = this.url + url;
        return this.http.delete(path + '/' + id, {
            headers: {'x-environment': envi}
        }).pipe(catchError(this.errorHandler));
    }

    doUpload(file: File, url: string): Observable<any> {
        const formData: FormData = new FormData();
        const path = this.url + url;
        formData.append('file', file);
        return this.http.post(path, formData, {
            reportProgress: true,
            responseType: 'json',
            observe: 'events'
        }).pipe(catchError(this.errorHandler));
    }

    doUploadFormPost(formData: FormData, url: string): Observable<any> {;
        const path = this.url + url;
        return this.http.post(path, formData, {
            reportProgress: true,
            responseType: 'json',
            observe: 'events'
        }).pipe(catchError(this.errorHandler));
    }
    doUploadFormPostFileType(formData: FormData, url: string): Observable<any> {;
        const path = this.url + url;
        return this.http.post(path, formData, {
            reportProgress: true,
        }).pipe(catchError(this.errorHandler));
    }

    doUploadFormPut(formData: FormData, url: string): Observable<any> {;
        const path = this.url + url;
        return this.http.put(path, formData, {
            reportProgress: true,
            responseType: 'json',
            observe: 'events'
        }).pipe(catchError(this.errorHandler));
    }
    

    getImage(url: string): Observable<any> {
        const path = this.url + url;
        return this.http.get(path, {
            responseType: 'blob'
        }).pipe(catchError(this.errorHandler));
    }

    public saveUser(user: any): Observable<any> {
        const url = '';  
        const header = new HttpHeaders();
        return this.http.post<any>(url, user,{
            headers: header
        }).pipe(catchError(this.errorHandler));
        
      }

}