<!-- Request Transformer -->
<div bsModal #reqTransModal="bs-modal" class="modal fade" id="reqTransModal" backdrop="static" keyboard="false"
    tabindex="-1" role="dialog" aria-labelledby="reqTransModalLabel" aria-hidden="true">
    <form [formGroup]="reqTransForm" (ngSubmit)="submitModal()">
        <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="modal-basic-title">{{tittle}} REQUEST TRANSFORMATION</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="hideModal()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <hr>
                <div class="modal-body">
                    <div class="form-group row">
                        <label class="col-sm-2 col-form-label">http method</label>
                        <div class="col-sm-10">
                            <textarea class="form-control" formControlName="httpMethod" id="httpMethod" >
                            </textarea>
                            <span *ngIf="reqTransForm.controls.httpMethod.invalid && reqTransForm.controls.httpMethod.errors && (reqTransForm.controls.httpMethod.dirty || reqTransForm.controls.httpMethod.touched)">
                                <small class="text-danger" *ngIf="reqTransForm.controls.httpMethod.hasError('pattern')">Only letters, numbers, and special characters !@#$%^&*()_+-= are allowed.</small>
                            </span>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-2 col-form-label">remove</label>
                        <div class="col-sm-10">
                            <div class="plugin-field-table">
                                <div *ngFor="let b of listRemove; index as i">
                                    <label class="col-sm-3 col-form-label">{{b.tittle}}</label>
                                    <div class="col-sm-12">
                                        <input type="text" class="form-control p-input" placeholder={{b.placeHolder}} formControlName={{b.formControl}}>
                                            <small> {{b.small}}</small><br>
                                            <span *ngIf="reqTransForm.controls[b.formControl].invalid && reqTransForm.controls[b.formControl].errors && (reqTransForm.controls[b.formControl].dirty || reqTransForm.controls[b.formControl].touched)">
                                                <small class="text-danger" *ngIf="reqTransForm.controls[b.formControl].hasError('pattern')">Only letters, numbers, and special characters !@#$%^&*()_+-= are allowed.</small>
                                            </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-2 col-form-label">rename</label>
                        <div class="col-sm-10">
                            <div class="plugin-field-table">
                                <div *ngFor="let b of listRename; index as i">
                                    <label class="col-sm-3 col-form-label">{{b.tittle}}</label>
                                    <div class="col-sm-12">
                                        <input type="text" class="form-control p-input" placeholder={{b.placeHolder}} formControlName={{b.formControl}}>
                                            <small> {{b.small}}</small><br>
                                            <span *ngIf="reqTransForm.controls[b.formControl].invalid && reqTransForm.controls[b.formControl].errors && (reqTransForm.controls[b.formControl].dirty || reqTransForm.controls[b.formControl].touched)">
                                                <small class="text-danger" *ngIf="reqTransForm.controls[b.formControl].hasError('pattern')">Only letters, numbers, and special characters !@#$%^&*()_+-= are allowed.</small>
                                            </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-2 col-form-label">replace</label>
                        <div class="col-sm-10">
                            <div class="plugin-field-table">
                                <div *ngFor="let b of listReplace; index as i">
                                    <label class="col-sm-3 col-form-label">{{b.tittle}}</label>
                                    <div class="col-sm-12">
                                        <input type="text" class="form-control p-input" placeholder={{b.placeHolder}} formControlName={{b.formControl}}>
                                            <small> {{b.small}}</small><br>
                                            <span *ngIf="reqTransForm.controls[b.formControl].invalid && reqTransForm.controls[b.formControl].errors && (reqTransForm.controls[b.formControl].dirty || reqTransForm.controls[b.formControl].touched)">
                                                <small class="text-danger" *ngIf="reqTransForm.controls[b.formControl].hasError('pattern')">Only letters, numbers, and special characters !@#$%^&*()_+-= are allowed.</small>
                                            </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-2 col-form-label">add</label>
                        <div class="col-sm-10">
                            <div class="plugin-field-table">
                                <div *ngFor="let b of listAdd; index as i">
                                    <label class="col-sm-3 col-form-label">{{b.tittle}}</label>
                                    <div class="col-sm-12">
                                        <input type="text" class="form-control p-input" placeholder={{b.placeHolder}} formControlName={{b.formControl}}>
                                        <small> {{b.small}}</small><br>
                                        <span *ngIf="reqTransForm.controls[b.formControl].invalid && reqTransForm.controls[b.formControl].errors && (reqTransForm.controls[b.formControl].dirty || reqTransForm.controls[b.formControl].touched)">
                                            <small class="text-danger" *ngIf="reqTransForm.controls[b.formControl].hasError('pattern')">Only letters, numbers, and special characters !@#$%^&*()_+-= are allowed.</small>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-2 col-form-label">append</label>
                        <div class="col-sm-10">
                            <div class="plugin-field-table">
                                <div *ngFor="let b of listAppend; index as i">
                                    <label class="col-sm-3 col-form-label">{{b.tittle}}</label>
                                    <div class="col-sm-12">
                                        <input type="text" class="form-control p-input" placeholder={{b.placeHolder}} formControlName={{b.formControl}}>
                                        <small> {{b.small}}</small><br>
                                        <span *ngIf="reqTransForm.controls[b.formControl].invalid && reqTransForm.controls[b.formControl].errors && (reqTransForm.controls[b.formControl].dirty || reqTransForm.controls[b.formControl].touched)">
                                            <small class="text-danger" *ngIf="reqTransForm.controls[b.formControl].hasError('pattern')">Only letters, numbers, and special characters !@#$%^&*()_+-= are allowed.</small>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-2 col-form-label">Enabled</label>
                        <div class="col-sm-10">
                            <div class="media-body text-end icon-state switch-outline">
                                <label class="switch">
                                    <input type="checkbox" checked="" formControlName="enabled"><span
                                        class="switch-state bg-primary"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="submit" class="btn btn-success" [disabled]="isLoading|| reqTransForm.invalid">Simpan</button>&nbsp;
                    <button type="button" class="btn btn-danger" (click)="hideModal()">Keluar</button>
                </div>
            </div>
        </div>
    </form>
</div>

<!-- End Request Transformer -->


<!-- Response Transformer -->


<!-- End Response Transformer -->