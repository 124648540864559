import { JsonPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { SharedService } from 'src/app/components/development/shared.service';
import { ServerService } from 'src/app/service/server.service';
import { environment } from 'src/environments/environment';


declare var require
const Swal = require('sweetalert2')

@Component({
  selector: 'app-transformations',
  templateUrl: './transformations.component.html',
  styleUrls: ['./transformations.component.scss']
})
export class TransformationsComponent implements OnInit {
  @ViewChild('reqTransModal') reqTransModal: ModalDirective;

  reqTransformSaveUrl = environment.path.base + environment.path.api_management.base + environment.path.api_management.request_transformer


  @Output("showModalPlugin") showModalPlugin: EventEmitter<any> = new EventEmitter();
  @Output("reloadDatatables") reloadDatatables: EventEmitter<any> = new EventEmitter();
  @Input() isModal: boolean;
  @Input() envi: string;
  @Input() id: number;
  @Input() fromMenu: string;
  tittle:string



  public reqTransForm: FormGroup;
  public respTransForm: FormGroup;
  key: String
  isEdit: Boolean
  pluginId: String

  listRemove=[]
  listRename=[]
  listReplace=[]
  listAdd=[]
  listAppend=[]

  isLoading: boolean

  constructor(private fb: FormBuilder,
    private server: ServerService,
    private shared: SharedService) {
    this.reqTransForm = fb.group({
      httpMethod: [null,[Validators.pattern(/^[a-zA-Z0-9!@#:/{\}[\],\$%\^\&*\)\(+=._ -]+$/)]],
      enabled: [false],
      removeBody : [[],[Validators.pattern(/^[a-zA-Z0-9!@#:/{\}[\],\$%\^\&*\)\(+=._ -]+$/)]],
      removeHeaders :  [[],[Validators.pattern(/^[a-zA-Z0-9!@#:/{\}[\],\$%\^\&*\)\(+=._ -]+$/)]],
      removeQueryString :  [[],[Validators.pattern(/^[a-zA-Z0-9!@#:/{\}[\],\$%\^\&*\)\(+=._ -]+$/)]],
      renameHeaders : [[],[Validators.pattern(/^[a-zA-Z0-9!@#:/{\}[\],\$%\^\&*\)\(+=._ -]+$/)]],
      renameQueryString : [[],[Validators.pattern(/^[a-zA-Z0-9!@#:/{\}[\],\$%\^\&*\)\(+=._ -]+$/)]],
      renameBody : [[],[Validators.pattern(/^[a-zA-Z0-9!@#:/{\}[\],\$%\^\&*\)\(+=._ -]+$/)]],
      replaceUri : [null,[Validators.pattern(/^[a-zA-Z0-9!@#:/{\}[\],\$%\^\&*\)\(+=._ -]+$/)]],
      replaceBody :[[],[Validators.pattern(/^[a-zA-Z0-9!@#:/{\}[\],\$%\^\&*\)\(+=._ -]+$/)]],
      replaceHeaders :[[],[Validators.pattern(/^[a-zA-Z0-9!@#:/{\}[\],\$%\^\&*\)\(+=._ -]+$/)]],
      replaceQueryString :[[],[Validators.pattern(/^[a-zA-Z0-9!@#:/{\}[\],\$%\^\&*\)\(+=._ -]+$/)]],
      addBody :[[],[Validators.pattern(/^[a-zA-Z0-9!@#:/{\}[\],\$%\^\&*\)\(+=._ -]+$/)]],
      addHeaders :[[],[Validators.pattern(/^[a-zA-Z0-9!@#:/{\}[\],\$%\^\&*\)\(+=._ -]+$/)]],
      addQueryString :[[],[Validators.pattern(/^[a-zA-Z0-9!@#:/{\}[\],\$%\^\&*\)\(+=._ -]+$/)]],
      appendHeaders:[[],[Validators.pattern(/^[a-zA-Z0-9!@#:/{\}[\],\$%\^\&*\)\(+=._ -]+$/)]],
      appendBody :[[],[Validators.pattern(/^[a-zA-Z0-9!@#:/{\}[\],\$%\^\&*\)\(+=._ -]+$/)]],
      appendQueryString :[[],[Validators.pattern(/^[a-zA-Z0-9!@#:/{\}[\],\$%\^\&*\)\(+=._ -]+$/)]],
    });

    this.shared.isLoading().subscribe((isLoading) => {
      this.isLoading = isLoading;
    })
   }

  ngOnInit(): void {
    // this.setListFieldReqTransform()
  }

  fetchDataModal(key, data){
    this.id= data.service ? data.service.id : data.route ? data.route.id : data.consumer ? data.consumer.id : null
    this.pluginId= data.id
    switch (key) {
      case 'reqTransform':
        this.reqTransForm.controls.httpMethod.setValue(data.config.httpMethod)
        this.reqTransForm.controls.enabled.setValue(data.enabled)
        this.reqTransForm.controls.removeBody.setValue(data.config.remove.body?data.config.remove.body.join(","):null)
        this.reqTransForm.controls.removeHeaders.setValue(data.config.remove.headers?data.config.remove.headers.join(","):null)
        this.reqTransForm.controls.removeQueryString.setValue(data.config.remove.querystring?data.config.remove.querystring.join(","):null)
        this.reqTransForm.controls.renameBody.setValue(data.config.rename.body?data.config.rename.body.join(","):null)
        this.reqTransForm.controls.renameHeaders.setValue(data.config.rename.headers?data.config.rename.headers.join(","):null)
        this.reqTransForm.controls.renameQueryString.setValue(data.config.rename.querystring?data.config.rename.querystring.join(","):null)
        this.reqTransForm.controls.replaceUri.setValue(data.config.replace.uri)
        this.reqTransForm.controls.replaceBody.setValue(data.config.replace.body?data.config.replace.body.join(","):null)
        this.reqTransForm.controls.replaceHeaders.setValue(data.config.replace.headers?data.config.replace.headers.join(","):null)
        this.reqTransForm.controls.replaceQueryString.setValue(data.config.replace.querystring?data.config.replace.querystring.join(","):null)
        this.reqTransForm.controls.addBody.setValue(data.config.add.body?data.config.add.body.join(","):null)
        this.reqTransForm.controls.addHeaders.setValue(data.config.add.headers?data.config.add.headers.join(","):null)
        this.reqTransForm.controls.addQueryString.setValue(data.config.add.querystring?data.config.add.querystring.join(","):null)
        this.reqTransForm.controls.appendHeaders.setValue(data.config.append.headers?data.config.append.headers.join(","):null)
        this.reqTransForm.controls.appendBody.setValue(data.config.append.body?data.config.append.body.join(","):null)
        this.reqTransForm.controls.appendQueryString.setValue(data.config.append.querystring?data.config.append.querystring.join(","):null)
        break;
      case 'respTransform':
        break;
      default:
        console.log("No key Choosen!!!")
        break;
    }
  }

  showModalAndSetForm(key){
    switch (key) {
      case 'reqTransform':
        this.reqTransModal.show()
        break;
      case 'respTransform':
        break;
      default:
        console.log("No key Choosen!!!")
        break;
    }
  }

  showAddModal(key, data) {
    this.setListFieldReqTransform()
    this.key = key
    if(data!=null){
      this.isEdit=true
      this.tittle='UBAH'
      this.fetchDataModal(key,data)
    }else{
      this.tittle='TAMBAH'
    }
    this.showModalAndSetForm(key)
  }

  setListFieldReqTransform(){
        this.listRemove=[
          { tittle: '> body',placeHolder:'body',formControl:'removeBody', small:'Gunakan koma tanpa spasi sebagai pemisah jika mengisi lebih dari 1 body' },
          { tittle: '> headers',placeHolder:'headers',formControl:'removeHeaders', small:'Gunakan koma tanpa spasi sebagai pemisah jika mengisi lebih dari 1 headers, Header di input dengan format seperti berikut headername:value' },
          { tittle: '> querystring',placeHolder:'querystring',formControl:'removeQueryString', small:'Gunakan koma tanpa spasi sebagai pemisah jika mengisi lebih dari 1 querystring'}
        ]
      
      
        this.listRename=[
          { tittle: '> body',placeHolder:'body', formControl:'renameBody', small:'Gunakan koma tanpa spasi sebagai pemisah jika mengisi lebih dari 1 body'},
          { tittle: '> headers',placeHolder:'headers', formControl:'renameHeaders', small:'Gunakan koma tanpa spasi sebagai pemisah jika mengisi lebih dari 1 headers, Header di input dengan format seperti berikut headername:value' },
          { tittle: '> querystring',placeHolder:'querystring', formControl:'renameQueryString', small:'Gunakan koma tanpa spasi sebagai pemisah jika mengisi lebih dari 1 querystring'}
        ]
      
        this.listReplace=[
          { tittle: '> body',placeHolder:'body',formControl:'replaceBody', small:'Gunakan koma tanpa spasi sebagai pemisah jika mengisi lebih dari 1 body'},
          { tittle: '> headers',placeHolder:'headers',formControl:'replaceHeaders', small:'Gunakan koma tanpa spasi sebagai pemisah jika mengisi lebih dari 1 headers, Header di input dengan format seperti berikut headername:value' },
          { tittle: '> querystring',placeHolder:'querystring',formControl:'replaceQueryString', small:'Gunakan koma tanpa spasi sebagai pemisah jika mengisi lebih dari 1 querystring'},
          { tittle: '> uri',placeHolder:'uri',formControl:'replaceUri', ngClass:"{ 'is-valid' : pathWizardFormGroup.controls.serviceCode.valid , 'is-invalid' : pathWizardFormGroup.controls.serviceCode.invalid }"}
        ]
      
      
        this.listAdd=[
          { tittle: '> body',placeHolder:'body',formControl:'addBody', small:'Gunakan koma tanpa spasi sebagai pemisah jika mengisi lebih dari 1 body'},
          { tittle: '> headers',placeHolder:'headers',formControl:'addHeaders', small:'Gunakan koma tanpa spasi sebagai pemisah jika mengisi lebih dari 1 headers, Header di input dengan format seperti berikut headername:value' },
          { tittle: '> querystring',placeHolder:'querystring',formControl:'addQueryString', small:'Gunakan koma tanpa spasi sebagai pemisah jika mengisi lebih dari 1 querystring'}
        ]
      
      
        this.listAppend=[
          { tittle: '> body',placeHolder:'body',formControl:'appendBody', small:'Gunakan koma tanpa spasi sebagai pemisah jika mengisi lebih dari 1 body'},
          { tittle: '> headers',placeHolder:'headers',formControl:'appendHeaders', small:'Gunakan koma tanpa spasi sebagai pemisah jika mengisi lebih dari 1 headers, Header di input dengan format seperti berikut headername:value' },
          { tittle: '> querystring',placeHolder:'querystring',formControl:'appendQueryString', small:'Gunakan koma tanpa spasi sebagai pemisah jika mengisi lebih dari 1 querystring'}
        ]
  }



  cekFieldMandatory(data){
    if(data.httpMethod == null || data.replaceUri == null || data.appendHeaders == null ||
      data.httpMethod == "" || data.replaceUri == "" || data.appendHeaders == ""){
      return false
    }else{
      return true
    }
  }

  addReqTransform(jsonData){
    let url = this.fromMenu=="services" && this.id ? this.reqTransformSaveUrl+'/service/'+this.id : this.fromMenu=="routes" && this.id?this.reqTransformSaveUrl+'/route/'+this.id: this.fromMenu=="consumers" && this.id?this.reqTransformSaveUrl+'/consumer/'+this.id :this.reqTransformSaveUrl
      this.server.doPostWithEnvi(jsonData, url, this.envi).subscribe(async resp => {
        if (resp) {
          Swal.fire('Success!', resp['message'], 'success').then((result) => {
            if (result.isConfirmed) {
              this.hideModal()
            }
          });
        } else {
          Swal.fire('Failure!', resp['message'], 'error')
        }
      })
  }

  editReqTransform(jsonData){
    let url = this.fromMenu=="services" && this.id ? this.reqTransformSaveUrl+'/'+this.pluginId+'/service/'+this.id : this.fromMenu=="routes" && this.id?this.reqTransformSaveUrl+'/'+this.pluginId+'/route/'+this.id: this.fromMenu=="consumers" && this.id?this.reqTransformSaveUrl+'/'+this.pluginId+'/consumer/'+this.id :this.reqTransformSaveUrl+'/'+this.pluginId
      this.server.doPutWithEnvi(jsonData, url, this.envi).subscribe(async resp => {
        if (resp) {
          Swal.fire('Success!', resp['message'], 'success').then((result) => {
            if (result.isConfirmed) {
              this.hideModal()
            }
          });
        } else {
          Swal.fire('Failure!', resp['message'], 'error')
        }
      })
  }

  submitReqTransform(){
    let data = this.reqTransForm.value
    let jsonData = {
      enabled: data.enabled,
      httpMethod:data.httpMethod ,
      removeBody:data.removeBody.length>0 ? data.removeBody.split(",") : [] ,
      removeHeaders:data.removeHeaders.length>0 ? data.removeHeaders.split(",") : [],
      removeQueryString:data.removeQueryString.length>0 ? data.removeQueryString.split(",") : [] ,
      renameBody:data.renameBody.length>0 ? data.renameBody.split(",") : [] ,
      renameHeaders:data.renameHeaders.length>0 ? data.renameHeaders.split(",") : [] ,
      renameQueryString:data.renameQueryString.length>0 ? data.renameQueryString.split(",") : [] ,
      replaceUri: data.replaceUri,
      replaceBody:data.replaceBody.length>0 ? data.replaceBody.split(",") : [] ,
      replaceHeaders:data.replaceHeaders.length>0 ? data.replaceHeaders.split(",") : [] ,
      replaceQueryString:data.replaceQueryString.length>0 ? data.replaceQueryString.split(",") : [] ,
      addBody:data.addBody.length>0 ? data.addBody.split(",") : [] ,
      addHeaders:data.addHeaders.length>0 ? data.addHeaders.split(",") : [] ,
      addQueryString:data.addQueryString.length>0 ? data.addQueryString.split(",") : [] ,
      appendHeaders: data.appendHeaders.length>0? data.appendHeaders.split(","):[],
      appendBody:data.appendBody.length>0 ? data.appendBody.split(",") : [] ,
      appendQueryString:data.appendQueryString.length>0 ? data.appendQueryString.split(",") : [] 
    }
    if(!this.isEdit){
      this.addReqTransform(jsonData)
    }else{
      this.editReqTransform(jsonData)
  }
  }

  

  submitModal(){
    switch (this.key) {
      case 'reqTransform':
        this.submitReqTransform()
        break;
      case 'respTransform':
        break;
      default:
        console.log("No key Choosen!!!");
        break;
    }

  }

  hideModal(){
    if(!this.isEdit){
      this.showModalPlugin.emit()
    }else{
      this.reloadDatatables.emit()
    }
    this.isEdit=false
    this.listAdd=[]
    this.listAppend=[]
    this.listRemove=[]
    this.listRename=[]
    this.listReplace=[]
    this.reqTransModal.hide()
  }

}
