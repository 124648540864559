<table class="table table-bordered  table-sm">
    <tr class="itemRequest">
        <th>Query String Name</th>
        <th>Data Type</th>
        <th>format</th>
        <th>Length</th>
        <th>Description</th>
        <th>Mandatory</th>
        <th *ngIf="!readonly">Action</th>
    </tr>
    <tr *ngIf="queryStringData.length < 1">
        <td colspan="8" class="text-center" > No Data
        </td>
    </tr>
    <ng-container  *ngFor="let data of queryStringData" >
        <tr >
            <td >
                {{data.name}}
            </td>
            <td>
                {{data.dataType}}
            </td>
            <td>
                {{data.format}}
            </td>
            <td>
                {{data.length}}
            </td>
            <td>
                {{data.description}}
            </td>
            <td>
                    <i *ngIf="!data.mandatory" class="fa fa-ban" style="color:red;"></i><i *ngIf="data.mandatory" class="fa fa-check" style="color:green;"></i>
            </td>
            <td *ngIf="!readonly">
                    <span class="linkAction pointer" (click)="onEdit(data)"><i class="fa fa-pencil"></i> Edit</span> &nbsp;
                    <span class="linkAction pointer" (click)="onDelete(data)"><i class="fa fa-remove"></i> Delete</span> &nbsp;
                    
            </td>
        </tr>
        <!-- <app-request-body-view style="display: contents"  [queryStringData]="data.children" [readonly]="false" [isChildren]="true" [level]="level+1" [dragStatus]="showDropZone"></app-request-body-view> -->
       
    </ng-container>
</table>