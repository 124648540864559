import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root',
})
export class CacheService {
  private cache: { [key: string]: any } = {};

  setCache(key: string, data: any) {
    this.cache[key] = data;
  }

  getCache(key: string): any {
    return this.cache[key];
  }

  clearCache(key: string) {
    delete this.cache[key];
  }
}