import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DragulaModule } from 'ng2-dragula';
import { TranslateModule } from '@ngx-translate/core';
// Components
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { FeatherIconsComponent } from './components/feather-icons/feather-icons.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { ContentComponent } from './components/layout/content/content.component';
import { FullComponent } from './components/layout/full/full.component';
import { LoaderComponent } from './components/loader/loader.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { TapToTopComponent } from './components/tap-to-top/tap-to-top.component';
import { SccInpuComponent } from './components/forms/scc-input.component';


// Header Elements Components
import { SearchComponent } from './components/header/elements/search/search.component';
import { MegaMenuComponent } from './components/header/elements/mega-menu/mega-menu.component';
import { LanguagesComponent } from './components/header/elements/languages/languages.component';
import { NotificationComponent } from './components/header/elements/notification/notification.component';
import { BookmarkComponent } from './components/header/elements/bookmark/bookmark.component';
import { CartComponent } from './components/header/elements/cart/cart.component';
import { MessageBoxComponent } from './components/header/elements/message-box/message-box.component';
import { MyAccountComponent } from './components/header/elements/my-account/my-account.component';
// Directives
import { DisableKeyPressDirective } from './directives/disable-key-press.directive';
import { OnlyAlphabetsDirective } from './directives/only-alphabets.directive';
import { OnlyNumbersDirective } from './directives/only-numbers.directive';
import { ShowOptionsDirective } from './directives/show-options.directive';
// Services
import { LayoutService } from './services/layout.service';
import { ModalService } from './services/modal.service';
import { CacheService } from './services/cache.service';
import { NavService } from './services/nav.service';
import { RequestBodyViewComponent } from './components/request-body-view/request-body-view.component';
import { RequestHeaderViewComponent } from './components/request-header-view/request-header-view.component';
import { ResponseBodyViewComponent } from './components/response-body-view/response-body-view.component';
import { DatatableNewestComponent } from './components/datatable-newest/datatable-newest.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { DataTablesModule } from 'angular-datatables';
import { AuthenticationComponent } from './components/authentication/authentication.component';
import { SecurityComponent } from './components/security/security.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TransformationsComponent } from './components/transformations/transformations.component';
import { TrafficControlComponent } from './components/traffic-control/traffic-control.component';
import { LoggingComponent } from './components/logging/logging.component';
import { OtherPluginsComponent } from './components/other-plugins/other-plugins.component';
import { QueryStringViewComponent } from './components/query-string-view/query-string-view.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    ContentComponent,
    BreadcrumbComponent,
    FeatherIconsComponent,
    FullComponent,
    ShowOptionsDirective,
    DisableKeyPressDirective,
    OnlyAlphabetsDirective,
    OnlyNumbersDirective,
    LoaderComponent,
    TapToTopComponent,
    SearchComponent,
    MegaMenuComponent,
    LanguagesComponent,
    NotificationComponent,
    BookmarkComponent,
    CartComponent,
    MessageBoxComponent,
    MyAccountComponent,
    SccInpuComponent,
    RequestBodyViewComponent,
    RequestHeaderViewComponent,
    ResponseBodyViewComponent,
    DatatableNewestComponent,
    AuthenticationComponent,
    SecurityComponent,
    TransformationsComponent,
    TrafficControlComponent,
    LoggingComponent,
    OtherPluginsComponent,
    QueryStringViewComponent,
    
    ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    DragulaModule.forRoot(),
    TranslateModule,
    NgxDatatableModule,
    DataTablesModule,
    ModalModule.forRoot(),
  ],
  providers: [
    NavService,
    LayoutService,
    CacheService,
    ModalService
  ],
  exports: [
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    LoaderComponent,
    BreadcrumbComponent,
    FeatherIconsComponent,
    TapToTopComponent,
    DisableKeyPressDirective,
    OnlyAlphabetsDirective,
    OnlyNumbersDirective,
    RequestBodyViewComponent,
    RequestHeaderViewComponent,
    ResponseBodyViewComponent,
    DatatableNewestComponent,
    AuthenticationComponent,
    SecurityComponent,
    TransformationsComponent,
    TrafficControlComponent,
    LoggingComponent,
    OtherPluginsComponent,
    QueryStringViewComponent
  ],
})
export class SharedModule { }
