<!-- Http Log Modal -->
<div bsModal #httpLogModal="bs-modal" class="modal fade" id="addPath" backdrop="static" keyboard="false" tabindex="-1"
    role="dialog" aria-labelledby="addModalLabel" aria-hidden="true">
    <form [formGroup]="httpLogForm">
        <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title " id="addModalLabel">Tambah Http Log</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <!--sementara di command karena request be dan field kongan berbeda-->
                    <!-- <div class="form-group row">
                        <label class="col-sm-3 col-form-label">consumer</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control p-input" placeholder="consumer" id="consumer"
                                name="consumer" value="" formControlName="consumer">
                        </div>
                    </div> -->
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">http endpoint</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control p-input" placeholder="http endpoint"
                                id="httpEndpoint" name="httpEndpoint" value="" formControlName="httpEndpoint">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">method</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control p-input" placeholder="method" id="method"
                                name="method" value="" formControlName="method">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">content type</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control p-input" placeholder="content type" id="contentType"
                                name="contentType" value="" formControlName="contentType">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">timeout</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control p-input" placeholder="timeout" id="timeout"
                                name="timeout" value="" formControlName="timeout">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">keepalive</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control p-input" placeholder="keepalive" id="keepalive"
                                name="keepalive" value="" formControlName="keepalive">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">retry count</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control p-input" placeholder="retry count" id="retryCount"
                                name="retryCount" value="" formControlName="retryCount">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">queue size</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control p-input" placeholder="queue size" id="queueSize"
                                name="queueSize" value="" formControlName="queueSize">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">flush timeout</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control p-input" placeholder="flush timeout"
                                id="flushTimeout" name="flushTimeout" value="" formControlName="flushTimeout">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">enabled</label>
                        <div class="col-sm-9 media-body text-end icon-state switch-outline">
                            <label class="switch">
                                <input type="checkbox" checked="" formControlName="enabled"><span
                                    class="switch-state bg-primary"></span>
                            </label>
                        </div>
                    </div>
                    <!-- <div class="form-group row">
                        <label class="col-sm-3 col-form-label">headers</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control p-input" placeholder="headers" id="headers"
                                name="headers" value="" formControlName="headers">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">custom fields by lua</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control p-input" placeholder="custom fields by lua"
                                id="customFieldsByLua" name="customFieldsByLua" value=""
                                formControlName="customFieldsByLua">
                        </div>
                    </div> -->
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-sm btn-success " (click)="submit()"
                        [disabled]="httpLogForm.invalid||isLoading">Simpan</button>
                    <button type="button" class="btn btn-sm btn-danger " (click)="close()">Kembali</button>
                </div>
            </div>
        </div>
    </form>
</div>
<!-- End Http Log Modal -->