import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  tenant: string;

  logo: string;

  logo_icon: string;

  isChangeImageLogin: boolean;

  isGeneratedKey:boolean;


  public config = {
    settings: {
      layout: 'Dubai',
      layout_type: 'ltr',
      layout_version: 'light-only',
      sidebar_type: 'default-sidebar'
    },
    color: {
      primary_color: '#013d77', 
      secondary_color: '#1b9e5e'
    }
  }

  constructor() { 
    if(this.config.settings.layout_type == 'rtl')
      document.getElementsByTagName('html')[0].setAttribute('dir', this.config.settings.layout_type);
      this.tenant=environment.tenant
      this.logo=environment.logo
      this.logo_icon=environment.logo_icon
      this.isChangeImageLogin=environment.isChangeImageLogin
      this.isGeneratedKey= environment.isGeneratedKey

    document.documentElement.style.setProperty('--theme-deafult', environment.primary_color);
    document.documentElement.style.setProperty('--theme-secondary', environment.secondary_color);
    this.setColor(this.tenant) 



  }

  setColor(tenant){
    switch (tenant) {
      case 'Bank Kalbar':
        document.documentElement.style.setProperty('--sidebar-color-default', '#049544');
        document.documentElement.style.setProperty('--sidebar-color-active', '#c0e5d0');
        break;
      case 'Bankaltimtara':
        document.documentElement.style.setProperty('--sidebar-color-default', '#c4b381');
        document.documentElement.style.setProperty('--sidebar-color-active', '#eae4d2');
        break;
      case 'Bank Maspion':
        document.documentElement.style.setProperty('--sidebar-color-default', '#dd2828');
        document.documentElement.style.setProperty('--sidebar-color-active', '#fbabab');
        break;
      case 'Bank Banten':
        document.documentElement.style.setProperty('--sidebar-color-default', '#dd2828');
        document.documentElement.style.setProperty('--sidebar-color-active', '#fbabab');
        break;
      case 'Bank IBK':
          document.documentElement.style.setProperty('--sidebar-color-default', '#0a5ca4');
          document.documentElement.style.setProperty('--sidebar-color-active', '#b3d8e8');
          break;
      case 'Bank BJBS':
        document.documentElement.style.setProperty('--sidebar-color-default', '#c4b381');
        document.documentElement.style.setProperty('--sidebar-color-active', '#eae4d2');
        break;
      default:
        console.log("No key Choosen!!!");
        break;
    }

  }

  getTenant() {
    return this.tenant;
  }


  getLogo() {
    return this.logo;
  }

  getLogoIcon(){
    return this.logo_icon;
  }

  getIsChangeImageLogin(){
    return this.isChangeImageLogin
  }

  getIsGeneratedKey(){
    return this.isGeneratedKey
  }


}
