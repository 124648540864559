import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
@Injectable({
    providedIn: 'root'
})
export class SharedService {
    private subject = new Subject<any>();

    private loading = new BehaviorSubject<boolean>(false);

    private isWrite = new BehaviorSubject<boolean>(false);

    private isDelete = new BehaviorSubject<boolean>(false);

    constructor(private sanitizer: DomSanitizer
      ){}


    
    sendClickEvent() {
        this.subject.next();
    }
    getClickEvent(): Observable<any> {
        return this.subject.asObservable();
    }

    showLoading() {
        this.loading.next(true);
        // Tampilkan indikator loading, seperti menampilkan spinner atau loading bar
    }
  
    hideLoading() {
      this.loading.next(false);
      // Sembunyikan indikator loading
    }
  
    isLoading(): Observable<boolean> {
      return this.loading.asObservable();
    }

     stripHtmlTags(htmlString: string): string {
      const sanitizedValue =this.sanitizer.sanitize(SecurityContext.HTML, htmlString);
      return sanitizedValue ? sanitizedValue.replace(/(<([^>]+)>)/gi, "") : '';
    }


    showWrite(){
      this.isWrite.next(true);
    }

    hideWrite(){
      this.isWrite.next(false);
    }

    write(): Observable<boolean> {
      return this.isWrite.asObservable();
    }


    showDelete(){
      this.isDelete.next(true);
    }

    hideDelete(){
      this.isDelete.next(false);
    }

    delete(): Observable<boolean> {
      return this.isDelete.asObservable();
    }
}